import React from 'react';
import { IconField } from 'components/molecules/IconField/IconField';

import bialkoicon from 'assets/icons/colorIcons/bialko-icon.svg';
import tluszczeicon from 'assets/icons/colorIcons/tluszcze-icon.svg';
import weglowodanyicon from 'assets/icons/colorIcons/weglowodany-icon.svg';
import styles from 'components/organisms/DietsPageIconsWrapper/DietsPageIconsWrapper.module.scss';

export const DietsPageIconsWrapper = ({ protein, fats, carbohydrates }) => {
  return (
    <div id="bilans" className={styles.container}>
      <h1 className={styles.container__title}>
        ŚREDNI TYGODNIOWY BILANS MAKROSKŁADNIKÓW
      </h1>
      <ul className={styles.wrapper}>
        <li>
          <IconField
            icon={bialkoicon}
            text={'BIAŁKO'}
            styleText={{ fontWeight: '700', fontSize: '24px' }}
            procents={protein}
            styleProcents={{
              fontSize: '24px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          />
        </li>

        <li>
          <IconField
            icon={tluszczeicon}
            text={'TŁUSZCZE'}
            styleText={{ fontWeight: '700', fontSize: '24px' }}
            procents={fats}
            styleProcents={{
              fontSize: '24px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          />
        </li>

        <li>
          <IconField
            icon={weglowodanyicon}
            text={'WĘGLOWODANY'}
            styleText={{ fontWeight: '700', fontSize: '24px' }}
            procents={carbohydrates}
            styleProcents={{
              fontSize: '24px',
              fontWeight: '400',
              textAlign: 'center',
            }}
          />
        </li>
      </ul>
    </div>
  );
};
