import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/molecules/DietsPageItemMini/DietsPageItemMini.module.scss';
export const DietsPageItemMini = ({ image, title }) => {
  return (
    <div className={styles.container}>
      <img className={styles.img} src={image} alt="dietphoto"></img>
      <p className={styles.title}>{title}</p>
    </div>
  );
};

DietsPageItemMini.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  setNewImage: PropTypes.func,
};
