import React from 'react';
import { IconField } from 'components/molecules/IconField/IconField';
import { Button } from 'components/atoms/Button/Button';

import styles from 'components/organisms/DietsPageInfoDietItem/DietsPageInfoDietItem.module.scss';
export const DietsPageInfoDietItem = ({
  title,
  kcalPriceInfo,
  description,
  icons,
}) => {
  return (
    <div className={styles.infoWrapper}>
      <h1>dieta</h1>
      <p className={styles.infoWrapper__title}>{title}</p>
      <div className={styles.kcalWrapper}>
        {kcalPriceInfo.map((diet, index) => (
          <div className={styles.kcalWrapper__kcalItem} key={index}>
            {diet.kcal} <span className={styles.kcalWrapper__span}>kcal</span>
          </div>
        ))}
      </div>
      <div className={styles.infoWrapper__description}>{description}</div>
      <ul className={styles.iconsWrapper}>
        {icons.map((icon, index) => (
          <li key={index} className={styles.iconsWrapper__item}>
            <IconField
              style={{ width: '100px', height: '100px' }}
              styleText={{ color: '#625847', width: '180px' }}
              icon={icon.icon}
              text={<p className={styles.iconTitle}>{icon.iconTitle}</p>}
            />
          </li>
        ))}
      </ul>
      <div className={styles.buttonsWrapper}>
        <a href="https://zamow.dailycious.pl/zamowienie/#/" rel="noreferrer">
          <Button text="zamów online" backgroundColor={'#ffb526'} />
        </a>
        <a
          href="https://menu.dailycious.pl/menu/"
          rel="noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          aktualne menu
        </a>
        <a
          href="#bilans"
          rel="noreferrer"
          style={{ textDecoration: 'underline' }}
        >
          bilans makroskładników
        </a>
      </div>
    </div>
  );
};
