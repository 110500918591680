import React from 'react';
import PropTypes from 'prop-types';
import nextarrowicon from 'assets/icons/nextarrow.svg';

import styles from 'components/atoms/NextArrowButton/NextArrowButton.module.scss';
export const NextArrowButton = ({ nextSlide }) => {
  return (
    <button className={styles.button} onClick={nextSlide}>
      <img src={nextarrowicon} alt="nextarrow"></img>
    </button>
  );
};

NextArrowButton.propTypes = {
  nextSlide: PropTypes.func,
};
