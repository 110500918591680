import React, { useContext } from 'react';
import { IconInstagram } from 'components/molecules/IconInstagram/IconInstagram';
import { PromotionPageContext } from 'providers/PromotionPageProvider';
import { IconFb } from 'components/molecules/IconFb/IconFb';
import { Helmet } from 'react-helmet-async';
import promophoto from 'assets/promocje/10-znizki-desktop.jpg';
import promophotomobile from 'assets/promocje/10-znizki-mobile.jpg';
import secondpromophoto from 'assets/promocje/sledz-nas-desktop.jpg';
import secondpromophotomobile from 'assets/promocje/sledz-nas-mobile.jpg';

import styles from 'components/templates/PromotionsPage/PromotionsPage.module.scss';
export const PromotiosPage = () => {
  const context = useContext(PromotionPageContext);
  return (
    <section className={styles.container}>
      <Helmet>
        <title>Dailycious | Promocje</title>
        <meta name="description" content="" />
      </Helmet>
      <img
        className={styles.container__img}
        src={context.windowWidthSize ? promophoto : promophotomobile}
        alt="promophoto"
      ></img>
      <div className={styles.sledzNasContainer}>
        <div className={styles.wrapper}>
          <h2 className={styles.wrapper__h2}>ŚLEDZ NAS</h2>
          <p className={styles.wrapper__p}>Więcej promocji znajdziesz tu:</p>
          <div className={styles.socialBox}>
            <IconInstagram
              width="40px"
              height="40px"
              text="@dailycious_catering"
            />
            <IconFb width="40px" height="40px" text="@dailyciouscatering" />
          </div>
        </div>

        <img
          className={styles.container__imgSledzNas}
          src={
            context.windowWidthSize ? secondpromophoto : secondpromophotomobile
          }
          alt="promophoto"
        ></img>
      </div>
    </section>
  );
};
