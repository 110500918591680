import React, { useState, useEffect } from 'react';

export const PromotionPageContext = React.createContext({
  windowWidthSize: '',
});

export const PromotionPageProvider = ({ children }) => {
  const [windowWidthSize, setWindowWidthSize] = useState(false);

  useEffect(() => {
    //Add event listener
    window.addEventListener('resize', handleResize());
  }, []);

  //Handler to call on window resize
  const handleResize = () => {
    const innerWidth = window.innerWidth;
    if (innerWidth > 999) {
      setWindowWidthSize(true);
    } else {
      setWindowWidthSize(false);
    }
  };

  return (
    <PromotionPageContext.Provider value={{ windowWidthSize }}>
      {children}
    </PromotionPageContext.Provider>
  );
};
