import React, { useContext } from 'react';
import { HomePageSlideCenterItem } from 'components/molecules/HomePageSlideCenterItem/HomePageSlideCenterItem';
import { HomePageSlideRightItem } from 'components/molecules/HomePageSlideRightItem/HomePageSlideRightItem';
import { HomePageSlideLeftItem } from 'components/molecules/HomePageSlideLeftItem/HomePageSlideLeftItem';
import { HomePageContext } from 'providers/HomePageProvider';
import { diets } from 'data/Diets';

import styles from 'components/organisms/HomePageSlideWrapper/HomePageSlideWrapper.module.scss';
export const HomePageSlideWrapper = () => {
  const context = useContext(HomePageContext);
  return (
    <div className={styles.container}>
      <HomePageSlideLeftItem
        diets={diets}
        currentSlideLeftItem={context.currentSliderLeftItem}
      />
      <HomePageSlideCenterItem
        diets={diets}
        currentSlideCenterItem={context.currentSliderCenterItem}
      />
      <HomePageSlideRightItem
        diets={diets}
        currentSlideRightItem={context.currentSliderRightItem}
      />
    </div>
  );
};
