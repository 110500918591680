import React from 'react';
import { Navigation } from 'components/organisms/Navigation/Navigation';
import { NavigationProvider } from 'providers/NavigationProvider';
import { Footer } from 'components/organisms/Footer/Footer';
import { HomePageProvider } from 'providers/HomePageProvider';

import styles from 'components/templates/MainTemplate/MainTemplate.module.scss';

export const MainTemplate = ({ children }) => {
  return (
    <section className={styles.contaier}>
      <NavigationProvider>
        <Navigation />
        {children}
        <HomePageProvider>
          <Footer />
        </HomePageProvider>
      </NavigationProvider>
    </section>
  );
};
