import React from 'react';
import { IconField } from 'components/molecules/IconField/IconField';
import iconautochlodnia from 'assets/icons/icon-autochłodnia.svg';
import icondostawa from 'assets/icons/icon-dostawa w godzinach.svg';
import icontelefon from 'assets/icons/icon-telefon.svg';

import styles from 'components/molecules/DeliveryCitiesPageIconsWrapper/DeliveryCitiesPageIconsWrapper.module.scss';
export const DeliveryCitiesPageIconsWrapper = () => {
  return (
    <ul className={styles.list}>
      <li className={styles.list__item}>
        <IconField
          icon={iconautochlodnia}
          text={
            <p className={styles.deliveryTitle}>transport autochłodniami</p>
          }
        />
      </li>
      <li className={styles.list__item}>
        <IconField
          icon={icondostawa}
          text={
            <p className={styles.deliveryTitle}>
              dostawa w godzinach <br /> 22:00-7:00
            </p>
          }
        />
      </li>
      <li className={styles.list__item}>
        <IconField
          icon={icontelefon}
          text={
            <p className={styles.deliveryTitle}>
              powiadomienie SMS <br /> o przybyciu dostawcy
            </p>
          }
        />
      </li>
    </ul>
  );
};
