import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/molecules/IconField/IconField.module.scss';
export const IconField = ({
  icon,
  text,
  style,
  styleText,
  procents,
  styleProcents,
}) => {
  return (
    <div className={styles.container}>
      <img style={style} className={styles.icon} src={icon} alt="icon"></img>
      <span style={styleText} className={styles.spanText}>
        {text}
      </span>
      <p style={styleProcents}>{procents}</p>
    </div>
  );
};

IconField.propTypes = {
  icon: PropTypes.string,
  style: PropTypes.object,
  styleText: PropTypes.object,
  styleProcents: PropTypes.object,
  procents: PropTypes.string,
};
