import React from 'react';
import { DeliveryCitiesPageInputField } from 'components/molecules/DeliveryCitiesPageInputField/DeliveryCitiesPageInputField';
import { DeliveryCitiesPageIconsWrapper } from 'components/molecules/DeliveryCitiesPageIconsWrapper/DeliveryCitiesPageIconsWrapper';
import { Helmet } from 'react-helmet-async';

import backgroundmapa from 'assets/dostawa/mapa.svg';
import styles from 'components/templates/DeliveryCitiesPage/DeliveryCitiesPage.module.scss';
export const DeliveryCitiesPage = () => {
  return (
    <>
      <section className={styles.container}>
        <Helmet>
          <title>Dailycious | Dostawa</title>
          <meta name="description" content="" />
        </Helmet>
        <img
          className={styles.img}
          src={backgroundmapa}
          alt="background-mapa"
        ></img>
        <DeliveryCitiesPageInputField />
        <DeliveryCitiesPageIconsWrapper />
      </section>
      <div className={styles.deliveryFooter}>
        <p className={styles.p}>
          O dostawy dbają profesjonaliści z BoxBrothers.
        </p>
      </div>
    </>
  );
};
