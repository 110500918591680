import React from 'react';
import { DietsPageItemMini } from 'components/molecules/DietsPageItemMini/DietsPageItemMini';
import { diets } from 'data/Diets';
import { Link } from 'react-router-dom';

import styles from 'components/organisms/DietsPageHeader/DietsPageHeader.module.scss';
export const DietsPageHeader = () => {
  return (
    <div className={styles.container}>
      {diets.map(({ title, image, id }) => (
        <Link key={id} to={`/diety/${title.toLowerCase().replace(/\s/g, '')}`}>
          <DietsPageItemMini image={image} title={title}></DietsPageItemMini>
        </Link>
      ))}
    </div>
  );
};
