import React, { useContext } from 'react';
import { IconField } from 'components/molecules/IconField/IconField';
import { FollowUsItem } from 'components/atoms/FollowUsItem/FollowUsItem';
import { NavigationContext } from 'providers/NavigationProvider';
import { Helmet } from 'react-helmet-async';

import contactphoto from 'assets/kontakt/photo-kontakt.jpg';
import telephonphoto from 'assets/icons/icon-telefon.svg';
import messagephoto from 'assets/icons/icon-powiadomienie sms.svg';
import styles from 'components/templates/ContactsPage/ContactsPage.module.scss';
export const ContactsPage = () => {
  const context = useContext(NavigationContext);
  return (
    <>
      <section className={styles.container}>
        <Helmet>
          <title>Dailycious | Kontakt</title>
        </Helmet>
        <img
          className={styles.container__img}
          src={contactphoto}
          alt="contactphoto"
        ></img>

        <div className={styles.box}>
          <div className={styles.iconsBox}>
            <ul className={styles.iconsBox__wrapper}>
              <li className={styles.iconsBox__wrapper__item}>
                <IconField
                  style={{ width: '300px', height: '300px' }}
                  icon={telephonphoto}
                  text={
                    <span className={styles.iconsText}>
                      <p className={styles.bold}>+48 690 549 599</p>
                      <p className={styles.regular}>
                        pon. - pt. <br />
                        10:00 - 18:00
                      </p>
                    </span>
                  }
                />
              </li>
              <li className={styles.iconsBox__wrapper__item}>
                <IconField
                  style={{ width: '300px', height: '300px' }}
                  icon={messagephoto}
                  text={
                    <span className={styles.bold}>kontakt@dailycious.pl</span>
                  }
                />
              </li>
            </ul>
          </div>

          <div className={styles.adresDataBox}>
            {/* <p className={styles.contactItem}>Dailycious</p> */}
            <p className={styles.contactItem}>
              Dane firmy <br />
              NIP: 5322040479 <br />
              REGON: 362994336
            </p>
            <p className={styles.contactItem}>
              Nr konta <br />
              69 1020 4724 0000 3602 0141 3095 <br />
              PKO BP
            </p>
          </div>
        </div>
      </section>
      {context.visable ? <FollowUsItem /> : null}
    </>
  );
};
