import React, { useContext } from 'react';
import { PricePageModalItem } from 'components/atoms/PricePageModalItem/PricePageModalItem';
import { PricesPageContext } from 'providers/PricesPageProvider';
import { diets } from 'data/Diets';

import styles from 'components/organisms/PricesPageModal/PricesPageModal.module.scss';
export const PricesPageModal = () => {
  const context = useContext(PricesPageContext);
  return (
    <ul className={styles.modal}>
      {diets.map((diet) => (
        <PricePageModalItem
          key={diet.title}
          description={diet.title}
          handleShowDietItem={() =>
            context.handleShowDiet(
              diet.title,
              diet.image,
              diet.background,
              diet.kcalPriceInfo
            )
          }
        />
      ))}
    </ul>
  );
};
