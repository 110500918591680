import React, { useContext } from 'react';
import { NavigationContext } from 'providers/NavigationProvider';
import { GrClose } from 'react-icons/gr';

import styles from 'components/organisms/PromotionBanner/PromotionBanner.module.scss';
export const PromotionBanner = ({ style }) => {
  const context = useContext(NavigationContext);

  return (
    <section style={style} className={styles.container}>
      <div className={styles.wrapper}>
        <img
          className={styles.wrapper__img}
          src={context.banner}
          alt="promocja"
        ></img>
        <button
          onClick={() => context.closeBanner()}
          className={styles.wrapper__button}
        >
          <GrClose size={25} />
        </button>
      </div>
    </section>
  );
};
