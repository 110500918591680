import React, { useContext } from 'react';
import { NavigationContext } from 'providers/NavigationProvider';

import mobileDietly from 'assets/widget/dietly-mobile.svg';
import desktopDietly from 'assets/widget/dietly-desktop.svg';

import styles from 'components/atoms/DietlyWidget/DietlyWidget.module.scss';
export const DietlyWidget = () => {
  const context = useContext(NavigationContext);
  return (
    <div className={styles.container}>
      <img
        className={styles.img}
        src={context.size[0] <= 999 ? mobileDietly : desktopDietly}
        alt="widget"
      />
    </div>
  );
};
