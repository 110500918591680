import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/atoms/PricePageModalItem/PricePageModalItem.module.scss';
export const PricePageModalItem = ({ description, handleShowDietItem }) => {
  return (
    <li className={styles.listItem}>
      <button onClick={handleShowDietItem} className={styles.button}>
        <p>{description}</p>
      </button>
    </li>
  );
};

PricePageModalItem.propTypes = {
  description: PropTypes.string,
  handleShowDietItem: PropTypes.func,
};
