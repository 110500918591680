import React, { useState } from 'react';
import { diets } from 'data/Diets';

export const DietsPageContext = React.createContext({
  currentDiet: {},
  setCurrentDiet: () => {},
  handleShowDiet: () => {},
});

const initialState = {
  title: diets[0].title,
  image: diets[0].image,
  background: diets[0].background,
  icons: diets[0].icons,
  kcalPriceInfo: diets[0].kcalPriceInfo,
  description: diets[0].description,
  protein: diets[0].protein,
  fats: diets[0].fats,
  carbohydrates: diets[0].carbohydrates,
};

export const DietsPageProvider = ({ children }) => {
  const [currentDiet, setCurrentDiet] = useState(initialState);

  //Handle to captures the props of each mapped diet
  const handleShowDiet = (
    title,
    image,
    background,
    icons,
    kcalPriceInfo,
    description,
    protein,
    fats,
    carbohydrates,
    id
  ) => {
    const newDiet = {
      title,
      image,
      background,
      icons,
      kcalPriceInfo,
      description,
      protein,
      fats,
      carbohydrates,
      id,
    };

    setCurrentDiet(newDiet);
  };

  if (!Array.isArray(diets) || diets.length === 0) return null;
  return (
    <DietsPageContext.Provider
      value={{
        currentDiet,
        setCurrentDiet,
        handleShowDiet,
      }}
    >
      {children}
    </DietsPageContext.Provider>
  );
};
