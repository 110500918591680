import React from 'react';
import { FooterContacts } from 'components/molecules/FooterContacts/FooterContacts';
import { FooterNavigation } from 'components/molecules/FooterNavigation/FooterNavigation';

import styles from 'components/organisms/Footer/Footer.module.scss';
export const Footer = () => {
  return (
    <div className={styles.container}>
      <FooterContacts />
      <FooterNavigation />
    </div>
  );
};
