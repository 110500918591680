import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { HomePageContext } from 'providers/HomePageProvider';
import { NavigationContext } from 'providers/NavigationProvider';
import { PrevArrowButton } from 'components/atoms/PrevArrowButton/PrevArrowButton';
import { Link } from 'react-router-dom';
import { NextArrowButton } from 'components/atoms/NextArrowButton/NextArrowButton';
import { Button } from 'components/atoms/Button/Button';

import styles from 'components/molecules/HomePageSlideCenterItem/HomePageSlideCenterItem.module.scss';

export const HomePageSlideCenterItem = ({ diets, currentSlideCenterItem }) => {
  const context = useContext(HomePageContext);
  const navigationContext = useContext(NavigationContext);

  const ref = useRef(null);

  useEffect(() => {
    if (context.animationStart === false) return;

    if (context.animationActive) {
      ref.current.classList.add(styles.prevAnimation);
    } else {
      ref.current.classList.add(styles.nextAnimation);
    }
  }, [context]);

  return (
    <section className={styles.con}>
      <div className={styles.containerText}>
        {navigationContext.size[0] <= 600 ? (
          <p className={styles.p}>
            EVERY DAY
            <br />
            TASTES <br /> GREAT
          </p>
        ) : (
          <p className={styles.p}>
            EVERY DAY
            <br />
            TASTES GREAT
          </p>
        )}
      </div>
      {diets.map((diet, index) => {
        return (
          index === currentSlideCenterItem && (
            <div key={index} className={styles.wrappercenter}>
              <PrevArrowButton prevSlide={context.prevSlide} />
              <img
                ref={ref}
                className={styles.imgcenter}
                src={diet.image}
                key={index}
                alt="dietphoto"
              ></img>

              <div className={styles.wrapper}>
                <div className={styles.span}>
                  <h1 className={styles.dietName}>{diet.title}</h1>
                </div>
                <ul className={styles.buttonsWrapper}>
                  <li className={styles.buttonsWrapperItem}>
                    <a
                      href="https://zamow.dailycious.pl/zamowienie/#/"
                      rel="noreferrer"
                    >
                      <Button text="zamów online" backgroundColor={'#ffb526'} />
                    </a>
                  </li>
                  <li className={styles.buttonsWrapperItem}>
                    <Link
                      to={`/diety/${diet.title
                        .toLowerCase()
                        .replace(/\s/g, '')}`}
                    >
                      <Button
                        text="więcej o diecie"
                        backgroundColor={'white'}
                        onClick={() => {
                          navigationContext.setNavigationListItemIndex(1);
                        }}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <NextArrowButton nextSlide={context.nextSlide} />
            </div>
          )
        );
      })}
    </section>
  );
};

HomePageSlideCenterItem.propTypes = {
  diets: PropTypes.array,
  currentSlideCenterItem: PropTypes.number,
};
