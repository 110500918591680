import React from 'react';
import { IconInstagram } from 'components/molecules/IconInstagram/IconInstagram';
import { IconFb } from 'components/molecules/IconFb/IconFb';

import styles from 'components/atoms/FollowUsItem/FollowUsItem.module.scss';
export const FollowUsItem = () => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.wrapper__h2}>ŚLEDZ NAS</h2>
      <p className={styles.wrapper__p}>Więcej promocji znajdziesz tu:</p>
      <div className={styles.socialBox}>
        <IconInstagram width="40px" height="40px" text="@dailycious_catering" />
        <IconFb width="40px" height="40px" text="@dailyciouscatering" />
      </div>
    </div>
  );
};
