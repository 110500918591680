import React from 'react';
import { Helmet } from 'react-helmet-async';

import styles from 'components/templates/PolitykaPrywatnosci/PolitykaPrywatnosci.module.scss';
export const PolitykaPrywatnosci = () => {
  return (
    <section id="documents" className={styles.documents}>
      <Helmet>
        <title>Dailycious | Polityka prywatności</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="container">
        <h5 className={styles.center}>
          Polityka Prywatności cateringu dietetycznego
        </h5>
        <h4 className={styles.center}>DAILYCIOUS</h4>
        <p className={styles.center}>
          <strong>I</strong>
        </p>
        <p className={styles.center}>
          <strong>Wprowadzenie</strong>
        </p>
        <p>Dziękujemy za wybranie DAILYCIOUS.!</p>
        <p>
          W naszym cateringu dietetycznym chcemy zapewnić Ci jak najlepsze
          doświadczenia, aby podobało Ci się korzystanie z naszych usług dziś,
          jutro i w przyszłości. Dlatego też jednym z naszych najważniejszych
          priorytetów zawsze będzie Twoja prywatność i bezpieczeństwo danych
          osobowych. Chcemy więc w sposób przejrzysty wyjaśnić, jak gromadzimy,
          przechowujemy, udostępniamy oraz wykorzystujemy Twoje dane osobowe, a
          także określić sposoby kontrolowania i określania preferencji, jakie
          masz do dyspozycji, udostępniając swoje dane osobowe naszej firmie
        </p>
        <p>
          Taki jest cel niniejszej Polityki Prywatności („Polityka”), która
          zawiera szczegółowe informacje na ten temat.
        </p>
        <p className={styles.center}>
          <strong>II</strong>
        </p>
        <p className={styles.center}>
          <strong>Informacje na temat Polityki</strong>
        </p>
        <p>
          W niniejszej Polityce zostały określone najważniejsze informacje
          dotyczące sposobu przetwarzania Twoich danych osobowych przez catering
          dietetyczny DAILYCIOUS. Niniejsza Polityka ma zastosowanie do
          wszystkich usług powiązanych z cateringiem dietetycznym. Warunki
          regulujące zbieranie danych osobowych są określone w Regulaminie
          Cateringu będącym podstawą prawną ich wykorzystania.
        </p>
        <p>
          Możemy okresowo opracowywać nowe lub oferować dodatkowe usługi. Jeśli
          wprowadzenie nowych lub dodatkowych usług spowoduje zmianę sposobu, w
          jaki gromadzimy lub przetwarzamy Twoje dane osobowe, przekażemy Ci
          dodatkowe informacje, warunki lub zasady. O ile nie określono inaczej,
          wprowadzane nowe lub dodatkowe usługi będą podlegać postanowieniom
          niniejszej Polityki.
        </p>
        <p>Celem niniejszej Polityki jest:</p>
        <ul>
          <li>
            zagwarantowanie, że rozumiesz, jakie dane osobowe pobieramy za Twoją
            zgodą oraz powody, dla których gromadzimy i wykorzystujemy te dane
            oraz komu je udostępniamy;
          </li>
          <li>
            wyjaśnienie, w jaki sposób wykorzystujemy dane osobowe, które nam
            podajesz, abyś z cieszył się radością korzystania z cateringu
            dietetycznego;
          </li>
          <li>
            wyjaśnienie Twoich praw i preferencji w odniesieniu do gromadzonych
            przez nas danych osobowych oraz sposobu, w jaki chronimy Twoją
            prywatność.
          </li>
        </ul>
        <p>
          Mamy nadzieję, że pomoże to Tobie zrozumieć nasze zobowiązania
          dotyczące prywatności.
        </p>
        <p>
          Informacje o tym, w jaki sposób skontaktować się z nami w przypadku
          jakichkolwiek pytań lub wątpliwości są dostępne w poniższej sekcji
          „Dane kontaktowe”. Ewentualnie, jeśli nie zgadzasz się z treścią
          niniejszej Polityki, pamiętaj, że samodzielnie decydujesz, czy chcesz
          korzystać z cateringu dietetycznego.
        </p>

        <p className={styles.center}>
          <strong>III</strong>
        </p>
        <p className={styles.center}>
          <strong>
            Twoje prawa i preferencje: Kontrola i możliwość wyboru
          </strong>
        </p>
        <p>
          Być może masz świadomość, że nowe prawo Unii Europejskiej, zwane
          ogólnym rozporządzeniem o ochronie danych osobowych lub „RODO”,
          przyznaje osobom fizycznym pewne prawa w odniesieniu do ich danych
          osobowych. W związku z tym wdrożyliśmy dodatkowe mechanizmy kontroli
          przejrzystości i dostępu w naszym cateringu:
        </p>
        <ul>
          <li>
            <strong>prawo dostępu</strong> — prawo do bycia informowanym oraz
            żądania dostępu do przetwarzanych przez nas danych osobowych;
          </li>
          <li>
            <strong>prawo do sprostowania</strong> — prawo do żądania
            poprawienia lub zaktualizowania danych osobowych, jeśli są one
            niedokładne lub niepełne;
          </li>
          <li>
            <strong>prawo do usunięcia</strong> — prawo do żądania usunięcia
            danych osobowych;
          </li>
          <li>
            <strong>prawo do ograniczenia</strong> — prawo do żądania
            zaprzestania przez nas tymczasowo lub na stałe przetwarzania
            wszystkich lub części danych osobowych;
          </li>
          <li>
            <strong>prawo do sprzeciwu</strong> — prawo do sprzeciwu, w dowolnym
            momencie, wobec przetwarzania przez nas danych osobowych z przyczyn
            odnoszących się do konkretnych sytuacji lub celów marketingowych;
          </li>
          <li>
            <strong>prawo do przenoszenia danych</strong> — prawo do zażądania
            kopii elektronicznej danych osobowych lub przekazania ich w celu
            wykorzystania przez strony trzecie;
          </li>
        </ul>
        <p className={styles.center}>
          <strong>IV</strong>
        </p>
        <p className={styles.center}>
          <strong>Sposób, w jaki gromadzimy dane osobowe</strong>
        </p>
        <p>Gromadzimy dane osobowe przy następujący sposób:</p>
        <p>
          Kiedy składasz zamówienie na catering dietetyczny — kiedy składasz
          zamówienie na catering dietetycznych gromadzone są konkretne dane
          osobowe pozwalające na realizację zamówienia takie jak imię, nazwisko,
          adres e-mail, adres zamieszkania, kod pocztowy, numer telefonu, numer
          konta bankowego,
        </p>
        <p>
          Kiedy trwa realizacja zamówienia - jaką zamawiasz dietę, gdzie oraz
          jakie masz preferencje żywieniowe
        </p>
        <p>
          Jeśli piszesz do nas e-maila – w zależności od jego treści zapisujemy
          wówczas informacje o uwagach do zamówienia, reklamacjach, czy
          preferencjach oraz oczywiście jeśli podajesz – dane osobowe niezbędne
          do realizacji zamówienia.
        </p>

        <p className={styles.center}>
          <strong>V</strong>
        </p>
        <p className={styles.center}>
          <strong>Dane osobowe, które gromadzimy</strong>
        </p>
        <p>
          W poniższych tabelach wyszczególniliśmy kategorie Twoich danych
          osobowych, jakie gromadzimy i wykorzystujemy:
        </p>
        <table className="table">
          <tbody>
            <tr>
              <th>Dane osobowe</th>
              <th>Cel przetwarzania</th>
            </tr>
            <tr>
              <td>Imię i nazwisko</td>
              <td>
                Weryfikacja płatności, uwag klienta oraz utrzymywanie
                korespondencji e-mail, dokonywanie zmian w zamówieniu, dostawach
              </td>
            </tr>
            <tr>
              <td>Adres e-mail</td>
              <td>
                Korespondencja e-mail, kontakt z klientem, rozliczenie zamówień
                oraz informowanie o ewentualnych zmianach w zamówieniu
              </td>
            </tr>
            <tr>
              <td>Numer telefonu</td>
              <td>
                Informowanie o nagłych opóźnieniach w dostawie, zmianach, czy
                przerwach w pracy cateringu oraz do kontaktu w razie trudności z
                dostawą produktu
              </td>
            </tr>
            <tr>
              <td>Adres dostawy</td>
              <td>Dostawa produktu, naliczanie zniżek lub dopłat za dostawę</td>
            </tr>
          </tbody>
        </table>
        <p>
          Podstawą prawną do przetwarzania wymienionych wyżej danych osobowych
          jest realizacja warunków Umowy Sprzedaży cateringu dietetycznego.
        </p>

        <p className={styles.center}>
          <strong>VI</strong>
        </p>
        <p className={styles.center}>
          <strong>Zatrzymywanie i usuwanie danych</strong>
        </p>
        <p>
          Twoje dane osobowe mogą być przetwarzane przez firmy, z którymi
          współpracujemy w celu realizacji warunków umowy sprzedaży. Są to firmy
          dostarczające oprogramowanie, czy kurierskie lub księgowe. Firmy te
          jedynie przetwarzają dane osobowe, ale nie są ich administratorami,
          więc nie mogą nimi w dowolny sposób dysponować.
        </p>
        <p>
          Twoje dane możemy udostępniać urzędom państwowym takim jak np. Urząd
          Skarbowy, czy inny podmiot, który będzie miał podstawę prawną do
          udostępnienia im Twoich danych osobowych.
        </p>
        <p className={styles.center}>
          <strong>VII</strong>
        </p>
        <p className={styles.center}>
          <strong>Zatrzymywanie i usuwanie danych</strong>
        </p>
        <p>
          Dane osobowe użytkownika przechowujemy tak długo, jak jest to
          konieczne, aby dostarczać catering dietetyczny, oraz w uzasadnionych i
          koniecznych celach biznesowych (m.in. zgodnie z art.74 ust. 1 i 2
          Ustawy o Rachunkowości), takich jak utrzymanie statystyk, czy realnych
          danych biznesowych, zapotrzebowania klientów na rozwój oferty, czy
          funkcjonalności Serwisu Internetowego oraz wywiązywanie się ze
          zobowiązań prawnych oraz rozstrzyganie konfliktów.
        </p>
        <p>
          Na Twój wniosek usuniemy lub zanonimizujemy Twoje dane osobowe, tak
          aby nie służyły one już do Twojej identyfikacji.
        </p>
        <p className={styles.center}>
          <strong>VIII</strong>
        </p>
        <p className={styles.center}>
          <strong>Bezpieczeństwo danych osobowych</strong>
        </p>
        <p>
          Naszym celem jest ochrona danych osobowych naszych użytkowników.
          Wdrażamy odpowiednie środki techniczne i organizacyjne, aby chronić
          Twoje dane osobowe, jednak pamiętaj, że żaden system nigdy nie jest
          całkowicie bezpieczny. Wdrożyliśmy różne zasady, takie jak Polityki
          Bezpieczeństwa Danych Osobowych, czy Instrukcje Zarządzania Systemami
          Informatycznymi, regularny przegląd miejsc przetwarzania danych
          osobowych, szkolenia pracowników, oraz szyfrowanie jednostek na
          których przetwarzane są dane osobowe.
        </p>
        <p className={styles.center}>
          <strong>IX</strong>
        </p>
        <p className={styles.center}>
          <strong>Zmiany w Polityce Prywatności</strong>
        </p>
        <p>Okresowo możemy zmieniać zapisy Polityki.</p>
        <p>
          W przypadku wprowadzenia istotnych zmian do niniejszej Polityki
          powiadomimy Cię o nich w sposób wyraźny i dostosowany do okoliczności
          np. wysyłając Ci wiadomość e-mail. W niektórych przypadkach
          powiadomimy Cię z wyprzedzeniem.
        </p>
        <p>Dlatego też prosimy o uważne czytanie takich powiadomień.</p>
        <p className={styles.center}>
          <strong>X</strong>
        </p>
        <p className={styles.center}>
          <strong>Dane kontaktowe</strong>
        </p>
        <p>
          Dziękujemy za przeczytanie naszej Polityki Prywatności. W przypadku
          jakichkolwiek pytań dotyczących niniejszej Polityki prosimy o kontakt
          z Administratorem Danych Osobowych za pomocą formularza „Kontakt”
          dostępnego w Serwisie Internetowym lub wysłanie do nas wiadomości na
          adres:
        </p>
        <p>biuro@dailycious.pl.</p>
        <p>
          Catering DAILYCIOUS jest administratorem danych dla celów związanych z
          przetwarzaniem danych osobowych w ramach tej Polityki.
        </p>
        <p className={styles.center}>Dziękujemy!</p>
        <p className={styles.center}>Zespół cateringu DAILYCIOUS.</p>
      </div>
    </section>
  );
};
