import axios from 'axios';
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { API_TOKEN } from 'data/constans';

export const NavigationContext = React.createContext({
  windowScroll: '',
  setWindowScroll: () => {},
  openMobileNavigation: '',
  setOpenMobileNavigation: () => {},
  handleToggleMobileNavigation: () => {},
  handleWindowScroll: () => {},
  navigationListItemIndex: '',
  setNavigationListItemIndex: () => {},
  handleScrollTo: () => {},
  handleNavigationListItemIndex: () => {},
  size: [],
  setSize: () => {},
  openBanner: '',
  setOpenBanner: () => {},
  closeBanner: () => {},
  banner: '',
});

export const NavigationProvider = ({ children }) => {
  const [windowScroll, setWindowScroll] = useState(false);
  const [openMobileNavigation, setOpenMobileNavigation] = useState(false);
  const [navigationListItemIndex, setNavigationListItemIndex] = useState(0);
  const [size, setSize] = useState([0, 0]);
  const [openBanner, setOpenBanner] = useState(false);
  const [banner, setBanner] = useState('');

  function useWindowSize() {
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    //Add event listener
    window.addEventListener('scroll', handleWindowScroll);
  }, []);

  useEffect(() => {
    axios
      .post(
        'https://graphql.datocms.com/',
        {
          query: `{
            allBanners {
              id
              banner {
                url
              }
            }
          }`,
        },
        {
          headers: {
            authorization: `Bearer ${API_TOKEN}`,
          },
        }
      )
      .then(({ data: { data } }) => {
        if (data.allBanners.length === 0) return;
        setBanner(data.allBanners[0].banner.url);
      })
      .catch((err) => console.log(err));
  }, []);

  //Handle to toggle open/close mobile navigation
  const handleToggleMobileNavigation = () => {
    setOpenMobileNavigation((prevState) => !prevState);
  };

  //Handle to call on window scroll
  const handleWindowScroll = () => {
    if (window.scrollY >= 2) {
      setWindowScroll(true);
    } else {
      setWindowScroll(false);
    }
  };

  //Handler to navigationlistitem index capture
  const handleNavigationListItemIndex = (id) => {
    setNavigationListItemIndex(id);
  };

  //Handler to call on window scrollTo
  const handleScrollTo = () => {
    window.scrollTo(0, 0);
  };

  const closeBanner = () => {
    setOpenBanner((prevState) => !prevState);
  };

  useWindowSize();

  return (
    <NavigationContext.Provider
      value={{
        windowScroll,
        setWindowScroll,
        openMobileNavigation,
        setOpenMobileNavigation,
        handleToggleMobileNavigation,
        handleWindowScroll,
        navigationListItemIndex,
        setNavigationListItemIndex,
        handleScrollTo,
        handleNavigationListItemIndex,
        size,
        openBanner,
        setOpenBanner,
        closeBanner,
        banner,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
