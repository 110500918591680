import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from 'components/atoms/NavigationListItem/NavigationListItem.module.scss';
export const NavigationListItem = ({ onClick, id, link, title, bg }) => {
  return (
    <Link className={styles.listItem} id={id} onClick={onClick} to={link}>
      <div
        style={{ background: `${bg}` }}
        className={styles.listItem__fillDiv}
      ></div>
      <li className={styles.listItem__title}>{title}</li>
    </Link>
  );
};

NavigationListItem.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.number,
  link: PropTypes.string,
  title: PropTypes.string,
  bg: PropTypes.string,
};
