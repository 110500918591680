import React, { useState } from 'react';
import { diets } from 'data/Diets';

export const HomePageContext = React.createContext({
  currentSliderCenterItem: '',
  setCurrentSliderCenterItem: () => {},
  currentSliderLeftItem: '',
  setCurrentSliderLeftItem: () => {},
  currentSliderRightItem: '',
  setCurrentSliderRightItem: () => {},
  nextSlide: () => {},
  prevSlide: () => {},
  animationActive: '',
  setAnimationActive: () => {},
  animationStart: '',
  setAnimationStart: () => {},
});

export const HomePageProvider = ({ children }) => {
  const [currentSliderCenterItem, setCurrentSliderCenterItem] = useState(0);
  const [currentSliderLeftItem, setCurrentSliderLeftItem] = useState(6);
  const [currentSliderRightItem, setCurrentSliderRightItem] = useState(1);
  const [animationActive, setAnimationActive] = useState(false);
  const [animationStart, setAnimationStart] = useState(false);

  const length = diets.length;

  const nextSlide = () => {
    setAnimationStart(true);
    setAnimationActive(true);

    //Function that changes the state of each component. Component status is compared with the index of each mapped component and displayed.
    setCurrentSliderCenterItem(
      currentSliderCenterItem === length - 1 ? 0 : currentSliderCenterItem + 1
    );
    setCurrentSliderLeftItem(
      currentSliderLeftItem === length - 1 ? 0 : currentSliderLeftItem + 1
    );
    setCurrentSliderRightItem(
      currentSliderRightItem === length - 1 ? 0 : currentSliderRightItem + 1
    );
  };

  //Function that changes the state of each component. Component status is compared with the index of each mapped component and displayed.
  const prevSlide = () => {
    setAnimationStart(true);
    setAnimationActive(false);

    setCurrentSliderCenterItem(
      currentSliderCenterItem === 0 ? length - 1 : currentSliderCenterItem - 1
    );
    setCurrentSliderLeftItem(
      currentSliderLeftItem === 0 ? length - 1 : currentSliderLeftItem - 1
    );
    setCurrentSliderRightItem(
      currentSliderRightItem === 0 ? length - 1 : currentSliderRightItem - 1
    );
  };

  if (!Array.isArray(diets) || diets.length === 0) return null;
  return (
    <HomePageContext.Provider
      value={{
        currentSliderCenterItem,
        setCurrentSliderCenterItem,
        currentSliderLeftItem,
        setCurrentSliderLeftItem,
        currentSliderRightItem,
        setCurrentSliderRightItem,
        nextSlide,
        prevSlide,
        animationActive,
        setAnimationActive,
        animationStart,
        setAnimationStart,
      }}
    >
      {children}
    </HomePageContext.Provider>
  );
};
