import React from 'react';

import styles from 'components/organisms/PricesPageSlider/PricesPageSlider.module.scss';
export const PricesPageSlider = ({ step, min, max, value, onChangeValue }) => {
  const handleChange = (max) => (e) => {
    onChangeValue(e);
  };
  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        type="range"
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={handleChange(max)}
      />
    </div>
  );
};
