import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { MainTemplate } from 'components/templates/MainTemplate/MainTemplate';
import { HomePage } from 'components/templates/HomePage/HomePage';
import { DietsPage } from 'components/templates/DietsPage/DietsPage';
import { PromotiosPage } from 'components/templates/PromotionsPage/PromotionsPage';
import { ContactsPage } from 'components/templates/ContactsPage/ContactsPage';
import { DeliveryCitiesPage } from 'components/templates/DeliveryCitiesPage/DeliveryCitiesPage';
import { PricesPage } from 'components/templates/PricesPage/PricesPage';
import { PricesPageProvider } from 'providers/PricesPageProvider';
import { DietsPageProvider } from 'providers/DietsPageProvider';
import { HomePageProvider } from 'providers/HomePageProvider';
import { DeliveryCitiesPageProvider } from 'providers/DeliveryCitiesPageProvider';
import { PromotionPageProvider } from 'providers/PromotionPageProvider';
import { PolitykaPrywatnosci } from 'components/templates/PolitykaPrywatnosci/PolitykaPrywatnosci';
import { Regulamin } from 'components/templates/Regulamin/Regulamin';
import { HelmetProvider } from 'react-helmet-async';
import { MenuPage } from 'components/templates/MenuPage/MenuPage';

export const history = createBrowserHistory();
function Root() {
  return (
    <>
      <HelmetProvider>
        <DietsPageProvider>
          <Router history={history}>
            <Switch>
              <MainTemplate>
                <Route exact path="/">
                  <HomePageProvider>
                    <HomePage />
                  </HomePageProvider>
                </Route>
                <Route path="/diety/:title">
                  <DietsPage />
                </Route>
                <Route path="/promocje">
                  <PromotionPageProvider>
                    <PromotiosPage />
                  </PromotionPageProvider>
                </Route>
                <Route path="/kontakt">
                  <ContactsPage />
                </Route>
                <Route path="/dostawa">
                  <DeliveryCitiesPageProvider>
                    <DeliveryCitiesPage />
                  </DeliveryCitiesPageProvider>
                </Route>
                <Route path="/cennik">
                  <PricesPageProvider>
                    <PricesPage />
                  </PricesPageProvider>
                </Route>
                <Route path="/polityka-prywatnosci">
                  <PolitykaPrywatnosci />
                </Route>
                <Route path="/regulamin">
                  <Regulamin />
                </Route>
                <Route path="/menu">
                  <MenuPage />
                </Route>
              </MainTemplate>
            </Switch>
          </Router>
        </DietsPageProvider>
      </HelmetProvider>
    </>
  );
}
export default Root;
