import React, { useContext } from 'react';
import { NavigationContext } from 'providers/NavigationProvider';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import styles from 'components/templates/Regulamin/Regulamin.module.scss';
export const Regulamin = () => {
  const context = useContext(NavigationContext);
  return (
    <section className={styles.documents}>
      <Helmet>
        <title>Dailycious | Regulamin</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="container">
        <h4 className={styles.center}>
          <strong>REGULAMIN CATERINGU DIETETYCZNEGO</strong>
        </h4>
        <h4 className={styles.center}>
          <strong>„DAILYCIOUS”</strong>
        </h4>
        <p className={styles.center}>
          <strong>&sect; 1</strong>
        </p>
        <h4 className={styles.center}>
          <strong>WPROWADZENIE</strong>
        </h4>
        <p>
          Właścicielem serwisu internetowego dostępnego pod adresem
          www.dailycious.com (zwany dalej „Sprzedawca” lub “Sprzedawca” )jest
          firma PRODUKCJA HANDEL USŁUGI KAROL KOBZA z siedzibą w Nadbrzeż kod
          05-480 przy ulicy Nadbrzeż 96a, wpisaną do rejestru przedsiębiorców
          Krajowego Rejestru Sądowego pod numerem, NIP 5322040479, REGON
          362994336, telefon: +48 602 627 527, poczta elektroniczna:
          biuro@dailycious.pl. Regulamin cateringu określa rodzaje i zakres
          świadczenia usług drogą elektroniczną, telefoniczną oraz za
          pośrednictwem Serwisu internetowego, zasady zawierania umów za pomocą
          Serwisu internetowego oraz poczty e-mail, zasady wykonywania tych
          umów, prawa i obowiązki Klienta i Sprzedawcy oraz tryb odstąpienia od
          umowy i postępowania reklamacyjnego.
        </p>
        <p className={styles.center}>
          <strong>&sect; 2</strong>
        </p>
        <p className={styles.center}>
          <strong>Definicje</strong>
        </p>
        <p>
          1. <strong>Sprzedawca</strong> - firma PRODUKCJA HANDEL USŁUGI KAROL
          KOBZA z siedzibą w Nadbrzeż kod 05-480 przy ulicy Nadbrzeż 96a,
          wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego pod
          numerem, NIP 5322040479, REGON 362994336, telefon: +48 602 627 527,
          poczta elektroniczna: biuro@dailycious.pl. Sprzedawca jest Stroną
          Umowy Sprzedaży oraz Administratorem Danych Osobowych przetwarzanych w
          celu realizacji Umowy.
        </p>
        <p>
          2. <strong>Produkt, Diet</strong>`a – Zestaw 4 lub 5 posiłków o
          łącznej kaloryczności od 1200 do 3500 kcal dostarczany do klientowi
          codziennie w dni robocze.
        </p>
        <p>
          3. <strong>Klient</strong> - każdy podmiot dokonujący zakupów za
          pośrednictwem Serwisu Internetowego, składający zamówienie
          telefonicznie lub drogą e-mail
        </p>
        <p>
          4. <strong>Serwis Internetowy</strong> - sklep internetowy prowadzony
          przez Sprzedawcę pod adresem internetowym www.dailycious.com.
        </p>
        <p>
          5. <strong>Umowa zawarta na odległość</strong> - umowa zawarta z
          Klientem w ramach zorganizowanego systemu zawierania umów na odległość
          (w ramach Serwisu Internetowego), bez jednoczesnej fizycznej obecności
          stron, z wyłącznym wykorzystaniem jednego lub większej liczby środków
          porozumiewania się na odległość do chwili zawarcia umowy włącznie.
        </p>
        <p>
          6. <strong>Regulamin</strong> - niniejszy regulamin Serwisu
          Internetowego.
        </p>
        <p>
          7. <strong>Zamówienie</strong> - oświadczenie woli Klienta składane za
          pomocą Formularza Zamówienia, poczty e-mail lub telefonicznie i
          zmierzające bezpośrednio do zawarcia Umowy Sprzedaży Produktu lub
          Produktów ze Sprzedawcą.
        </p>
        <p>
          8. <strong>Umowa Sprzedaży</strong> - umowa sprzedaży Produktu
          zawierana albo zawarta między Klientem a Sprzedawcą za pośrednictwem
          Sklepu internetowego. Przez Umowę Sprzedaży rozumie się też -
          stosowanie do cech Produktu - umowę o świadczenie.
        </p>
        <p>
          9. <strong>Podmiot Zewnętrzny</strong> – firmy współpracujące ze
          Sprzedawcą w celu realizacji warunków umowy Sprzedaży pomiędzy
          Sprzedawcą a Klientem. Firmy te przetwarzają dane osobowe jedynie w
          celach wynikających z Umowy.
        </p>
        <p>
          10. <strong>Dzień roboczy</strong> – jeden dzień od poniedziałku do
          piątku za wyjątkiem dni ustawowo wolnych od pracy.
        </p>
        <p>
          11. <strong>Ustawa o prawach konsumenta, Ustawa</strong> – ustawa z
          dnia 30 maja 2014 r. o prawach konsumenta (Dz.U. 2014 poz. 827 ze
          zm.).
        </p>
        <p>
          12. <strong>Strefa dowozu</strong> – obszar dostaw Usługi wskazany w
          zakładce{' '}
          {
            <Link onClick={() => context.handleScrollTo()} to="/dostawa">
              www.dailycious.pl/dostawa
            </Link>
          }{' '}
          dostępnej na stronie Serwisu Internetowego obejmujący wskazane miasta
          główne oraz okoliczne miejscowości. Możliwość dostawy pod adres
          wskazany przez Klienta w trakcie składania Zamówienia potwierdzana
          jest przez Sprzedawcę podczas przyjęcia Zamówienia do realizacji.
          Dodatkowo w sytuacji niemożności realizacji dostawy pod podany adres,
          Klient jest o tym informowany.
        </p>
        <p>
          13. <strong>Kodeks cywilny</strong> – ustawa kodeks cywilny z dnia 23
          kwietnia 1964 r. (Dz. U. Nr 16, poz. 93 ze zm.).
        </p>
        <p className={styles.center}>
          <strong>&sect; 3</strong>
        </p>
        <p className={styles.center}>
          <strong>Postanowienia ogólne</strong>
        </p>
        <p>
          1. Sprzedawca zobowiązuje się do świadczenia usług na rzecz Klienta w
          zakresie i na warunkach określonych w Regulaminie.
        </p>
        <p>
          2. Klient zobowiązuje się do korzystania z Serwisu internetowego
          zgodnie z obowiązującymi przepisami prawa i zasadami współżycia
          społecznego. Klient korzystający z usług Sprzedawcy zobowiązany jest
          do przestrzegania niniejszego Regulaminu.
        </p>
        <p>
          3. Klient wyraża zgodę na gromadzenie, przechowywane i przetwarzanie
          przez Sprzedawcę oraz Podmioty Zewnętrzne. Dane osobowe, które są
          przetwarzane oraz cel ich przetwarzania to:
        </p>
        <table className="table">
          <tbody>
            <tr>
              <th>Dane osobowe</th>
              <th>Cel przetwarzania</th>
            </tr>
            <tr>
              <td>Imię i nazwisko</td>
              <td>
                Weryfikacja płatności, uwag klienta oraz utrzymywanie
                korespondencji e-mail, dokonywanie zmian w zamówieniu, dostawach
              </td>
            </tr>
            <tr>
              <td>Adres e-mail</td>
              <td>
                Korespondencja e-mail, kontakt z klientem, rozliczenie zamówień
                oraz informowanie o ewentualnych zmianach w zamówieniu
              </td>
            </tr>
            <tr>
              <td>Numer telefonu</td>
              <td>
                Informowanie o nagłych opóźnieniach w dostawie, zmianach, czy
                przerwach w pracy cateringu oraz do kontaktu w razie trudności z
                dostawą produktu
              </td>
            </tr>
            <tr>
              <td>Adres dostawy</td>
              <td>Dostawa produktu, naliczanie zniżek lub dopłat za dostawę</td>
            </tr>
          </tbody>
        </table>
        <p>
          4. Szczegółowe warunki gromadzenia, przetwarzania oraz ochrony danych
          osobowych przez Sprzedawcę określa „Polityka prywatności” cateringu
          internetowego
        </p>
        <p>
          5. Wszelkie ceny podane na stronie Serwisu internetowego podawane są w
          polskich złotych (PLN), są cenami brutto zawierającymi wszystkie
          niezbędne składniki
        </p>
        <p className={styles.center}>
          <strong>&sect; 4</strong>
        </p>
        <p className={styles.center}>
          <strong>Zamówienie Produktu</strong>
        </p>
        <p>
          1. Sprzedawca umożliwia Klientowi złożenie zamówienia poprzez Serwis
          Internetowy wyboru Produktu na konkretną ilość dni oraz dostaw zgodnie
          z ofertą cateringu opisaną w zakładce{' '}
          <a href="htttps://zamow.dailycious.pl">
            htttps://zamow.dailycious.pl
          </a>
        </p>
        <p>
          2. Sprzedawca zastrzega sobie prawo do powiększenia oferty o nowe
          Produkty. Dodanie nowych Produktów nie stanowi zmiany Regulaminu.
        </p>
        <p>3. Sprzedawca umożliwia składanie zamówień:</p>
        <ul>
          <li>
            drogą elektroniczną na adres kontakt@dailycious.pl przez 7 dni w
            tygodniu 24 godziny na dobę, za pośrednictwem Formularza zamówienia
            dostępnego w Serwisie internetowym przez 7 dni w tygodniu 24 godziny
            na dobę.
          </li>
        </ul>
        <p>
          4. W celu złożenia zamówienia Klient dokonuje wskazania Produktu
          oferowanego przez Sprzedawcę, określając rodzaj diety, wariant diety
          oraz kaloryczność posiłków wchodzących w skład Produktu oraz dni,
          które Produkt będzie dostarczany. Poza wskazaniem Produktu, Klient
          podaje także dane niezbędne do realizacji zamówienia w tym Dane
          osobowe oraz adres do dostawy. Dane osobowe oraz sposób ich
          przetwarzania jest opisany w Polityce Prywatności cateringu oraz
          punkcie III niniejszego regulaminu
        </p>
        <p>
          5. Po otrzymaniu zamówienia Sprzedawca przesyła Klientowi drogą
          elektroniczną na podany podczas składania zamówienia adres poczty
          elektronicznej oświadczenie o przyjęciu zamówienia stanowiące
          równocześnie jego potwierdzenie. Z chwilą otrzymania wiadomości przez
          Klienta dochodzi do zawarcia Umowy.
        </p>
        <p>
          6. Wiadomość potwierdzająca zamówienie zawiera ustalone warunki
          zamówienia, a w szczególności ilość oraz rodzaj Produktu, całkowitą
          cenę do zapłaty (wraz z kosztami dostawy) oraz informację o
          przewidywanym czasie dostawy Produktu.
        </p>
        <p>
          7. Klient ma prawo do zgłoszenia zastrzeżeń co do treści zawartych w
          potwierdzeniu zamówienia w ciągu 12 godzin od otrzymania wiadomości od
          Sprzedawcy. Niezgłoszenie zastrzeżeń w zakreślonym terminie jest
          równoznaczne z akceptacją warunków zamówienia.
        </p>
        <p>
          8. Klient ma prawo zamówienia maksymalnie dwóch Produktów jako zestaw
          próbny. Kolejne zamówienia będą traktowane jako złożenie zamówienia na
          Produkt w regularnej cenie odpowiadającej cenie Produktu w najkrótszym
        </p>
        <p className={styles.center}>
          <strong>&sect; 5</strong>
        </p>
        <p className={styles.center}>
          <strong>
            Warunki realizacji Umowy Sprzedaży oraz dostawy produktu
          </strong>
        </p>
        <p>
          1. Zamówienia w cateringu są przyjmowane z wyprzedzeniem pozwalającym
          Sprzedawcy na staranne przygotowanie zamówienia. Czas realizacji
          zamówienia jest przedstawiony w tabeli poniżej:
        </p>
        <p>Tabela 1</p>
        <table className="table">
          <tbody>
            <tr>
              <th>Zmiana/Zamówienie potwierdzone w:</th>
              <th>Do godz.:</th>
              <th>Zostanie dostarczone/zmienione w:</th>
              <th>Zamówienie złożone po godz.</th>
              <th>Zostanie dostarczone/zmienione w:</th>
            </tr>
            <tr>
              <td>Poniedziałek</td>
              <td>15:00</td>
              <td>Środa do 7:00</td>
              <td>15:00</td>
              <td>Czwartek do 7:00</td>
            </tr>
            <tr>
              <td>Wtorek</td>
              <td>15:00</td>
              <td>Czwartek do 7:00</td>
              <td>15:00</td>
              <td>Piątek do 7:00</td>
            </tr>
            <tr>
              <td>Środę</td>
              <td>15:00</td>
              <td>Piątek do 7:00</td>
              <td>15:00</td>
              <td>Poniedziałek do 7:00</td>
            </tr>
            <tr>
              <td>Czwartek</td>
              <td>15:00</td>
              <td>Poniedziałek do 7:00</td>
              <td>15:00</td>
              <td>Poniedziałek do 7:00</td>
            </tr>
            <tr>
              <td>Piątek</td>
              <td>15:00</td>
              <td>Poniedziałek</td>
              <td>15:00</td>
              <td>Poniedziałek do 7:00</td>
            </tr>
            <tr>
              <td>Sobotę</td>
              <td>15:00</td>
              <td>Poniedziałek</td>
              <td>15:00</td>
              <td>Wtorek do 7:00</td>
            </tr>
            <tr>
              <td>Niedzielę</td>
              <td>15:00</td>
              <td>Środa</td>
              <td>15:00</td>
              <td>Środa do 7:00</td>
            </tr>
          </tbody>
        </table>
        <p>2. Zamówienie potwierdzone jest w momencie:</p>
        <ul>
          <li>
            Przesłania na adres e-mail Biura Obsługi Klienta biuro@dailycious.pl
            potwierdzenia płatności z konta bankowego zawierającego szczegóły
            pozwalające na identyfikację zamówienia (Imię, nazwisko, adres,
            zamówiony Produkt)
          </li>
          <li>
            Dokonanie płatności online przez dostawcę płatności internetowych
          </li>
        </ul>
        <p>
          3. Godziny w Tabeli 1są terminami niepodważalnymi i
          nieprzekraczalnymi.
        </p>
        <p>
          4. Godziny w Tabeli 1 są również wiążące w stosunku do dokonywania
          zmian w zamówieniu takich jak zmiana Produktu lub adresu dostawy.
        </p>
        <p>
          5. Odstępstwa od pkt.3 i 4 mogą być stosowane, ale tylko i wyłącznie
          po uzgodnieniu ze Sprzedawcą i e-mailowym potwierdzeniu z jego strony.
        </p>
        <p>
          6. Sprzedawca zastrzega sobie prawo do wstrzymania lub odmowy
          realizacji zamówień w następujących przypadkach:
        </p>
        <ul>
          <li>
            złożenia zamówienia w sposób nieprawidłowy, uniemożliwiający jego
            realizację;
          </li>
          <li>
            bezskuteczności uzgodnienia z Klientem miejsca i terminu dostawy
            Produktu;
          </li>
          <li>braku dokonania płatności za zakupiony Produkt;</li>
          <li>
            rażącego naruszenia przez Klienta postanowień niniejszego
            Regulaminu.
          </li>
        </ul>
        <p>
          7. Koszt dostawy Produktu na terenie miast wymienionych na stronie
          Sprzedawcy jest wliczony w cenę Produktu określonego w „Cenniku”
          dostępnym na stronie Serwisu internetowego, z wyłączeniem opisanym w
          następnym zdaniu.
        </p>
        <p>
          8. W przypadku złożenia zamówienia z dostawą poza teren, w którym jest
          wliczona cena produktu, koszt dostawy zostanie ustalony z Klientem
          indywidualnie.
        </p>
        <p>
          9. Sprzedawca zastrzega sobie prawo do ustalenia z Klientem nowego
          terminu realizacji zamówienia w przypadku, gdy realizacja zamówienia w
          pierwotnym terminie jest niemożliwa ze względu na przyczyny niezależne
          od Sprzedawcy, a których usunięcie nie jest możliwe.
        </p>
        <p>
          10. Klient ma prawo do wstrzymania dostaw w trakcie trwania okresu
          zamówienia po wcześniejszym telefonicznym lub za pośrednictwem poczty
          e-mail powiadomieniu Sprzedawcy. Informacja o wstrzymaniu dostaw
          powinna zostać zgłoszona z wyprzedzeniem określonym w Tabeli 1. Okres
          zamówienia zostanie przedłużony o ilość dni, w których doszło do
          wstrzymania dostawy.
        </p>
        <p>
          11. Zrealizowanie zamówienia z uwzględnieniem informacji, o których
          mowa w ust. 13 powyżej, nie stanowi przyjęcia odpowiedzialności
          Sprzedawcy za ewentualne niekorzystne samopoczucie Klienta lub
          negatywną reakcję organizmu na zestaw składników, w szczególności z
          powodu braku wiedzy i wpływu Sprzedawcy o pozostałych przyjmowanych
          przez klienta płynach i produktach.
        </p>
        <p>
          12. W razie nieodebrania przez Klienta Zamówienia spowodowane
          nieobecnością osoby upoważnionej do odbioru zamówienia lub jakąkolwiek
          inną okolicznością/okolicznościami nie zgłoszoną w terminach
          oznaczonych w Tabeli 1, bądź błędnie podanymi szczegółami adresowymi
          dostawy, przedmiot zamówienia uważa się za skutecznie doręczony.
          Klient nie jest w takim wypadku uprawniony do domagania się zwrotu
          należności za Zamówienie w części przypadającej na dany dzień lub
          zgłaszania jakichkolwiek innych roszczeń z tytułu nieotrzymania
          Zamówienia.
        </p>
        <p>
          13. W sytuacji gdy wystąpią czynniki niezależne od Sprzedawcy, które
          utrudnia lub uniemożliwią dostawę Produktu, nie będzie możliwości
          dochodzenia odszkodowania lub zwrotu. Mowa tu o przypadkach np.
          związanych z dużymi utrudnieniami pogodowymi.
        </p>
        <p>
          14. W przypadku, gdy dojdzie do przywłaszczenia Produktu przez osoby
          trzecie po dostarczeniu przez Sprzedawcę, Sprzedawca nie ponosi
          odpowiedzialności za niewywiązanie się z Umowy. Dostarczenie pod
          wskazany adres paczki jest dokumentowane wykonaniem zdjęcia przez
          Sprzedawcę.
        </p>
        <p>
          15. W przypadku rezygnacji z zamówienia Klientowi przysługuje zwrot
          kwoty za niewykorzystane dni z uwzględnieniem czasu rozwiązania umowy
          określonego w Tabeli 1.
        </p>
        <p>
          16. W przypadku zmiany Produktu w czasie realizacji zamówień na inny
          Produkt, Klient musi dokonać opłaty różnicy w zamówieniu. Zmiana
          zostanie uwzględniona zgodnie z Tabelą 1.
        </p>
        <p className={styles.center}>
          <strong>&sect; 6</strong>
        </p>
        <p className={styles.center}>
          <strong>Metody płatności</strong>
        </p>
        <p>1. Sprzedawca umożliwia następujące metody płatności:</p>
        <ul>
          <li>
            a. przelew tradycyjny na rachunek bankowy Sprzedawcy o numerze: 69
            1020 4724 0000 3602 0141 3095
          </li>
          <li>
            przelew online lub płatność kartą za pośrednictwem systemu tPay.com.
          </li>
        </ul>
        <p>
          2. Wpłatę za zamówiony Produkt należy dokonać w pełnej kwocie w
          terminie 3 dni od momentu potwierdzenia zamówienia. W przypadku braku
          wpłaty za zamówienie w tym terminie, zamówienie jest traktowane jako
          anulowane.
        </p>
        <p>
          3. Za dzień płatności uważany jest dzień zaksięgowania wpłaty na
          koncie Sprzedawcy, bądź przesłania potwierdzenia przelewu
          elektronicznego w formie pliku PDF.
        </p>
        <p className={styles.center}>
          <strong>&sect; 7</strong>
        </p>
        <p className={styles.center}>
          <strong>Odstąpienie od Umowy dostawy Produktu</strong>
        </p>
        <p>
          Sprzedawca informuje, iż na podstawie art. 38 pkt. 4 oraz pkt. 12
          ustawy z dnia 30 maja 2014 r. o prawach konsumenta, Klientowi będącemu
          konsumentem nie przysługuje prawo do odstąpienia od umowy, o którym
          mowa w art. 27 wymienionej ustawy.
        </p>
        <p className={styles.center}>
          <strong>&sect; 8</strong>
        </p>
        <p className={styles.center}>
          <strong>Postępowanie reklamacyjne</strong>
        </p>
        <p>
          1. Reklamacje związane z realizacją Umowy dotyczącej Produktu należy
          składać w terminie 24 godzin od dnia wystąpienia zdarzenia
          uzasadniającego zgłoszenie reklamacji. Wyjątkiem są reklamacje
          dotyczące niezrealizowanej dostawy – w takim przypadku reklamację
          należy złożyć do godz. 12.00 w dniu, w którym dostawa miała zostać
          zrealizowana. Reklamacje rozpatruje Sprzedawca w terminie 7 dni
          kalendarzowych od dnia otrzymania reklamacji za wyjątkiem reklamacji
          dotyczącej dostawy, która rozpatrywana jest niezwłocznie.
        </p>
        <p>2. Złożona reklamacja powinna zawierać:</p>
        <ul>
          <li>
            Identyfikację Klienta (imię i nazwisko lub nazwa firmy, adres
            zamieszkania lub adres siedziby firmy oraz adres poczty
            elektronicznej).
          </li>
          <li>
            Przedmiot reklamacji, dokumentację fotograficzną wraz ze wskazaniem
            okresu czasu, którego dotyczy reklamacja oraz okoliczności
            uzasadniające złożenie reklamacji
          </li>
        </ul>
        <p>
          3. W przypadku przekroczenia terminów do zgłoszenia reklamacji
          określonych w ust. 1 – 2, reklamacja nie będzie rozpatrywana. O
          przyczynie nierozpatrzenia reklamacji Sprzedawca niezwłocznie
          powiadomi Klienta. Zwroty należności za reklamacje rozpatrzone
          pozytywnie na korzyść Klienta, będą dokonywane w nie później niż w
          ciągu 14 dni roboczych.
        </p>
        <p className={styles.center}>
          <strong>&sect; 9</strong>
        </p>
        <p className={styles.center}>
          <strong>Odpowiedzialność Sprzedawcy</strong>
        </p>
        <p>
          1. Sprzedawca nie ponosi odpowiedzialności za wprowadzenie przez
          Klienta błędnych danych (w szczególności przez podanie błędnych danych
          w formularzach dostępnych na stronie np. Osobowych, czy kontaktowych)
          lub działanie Klienta w sposób utrudniający lub uniemożliwiający
          świadczenie i realizację usług przez Sprzedawcę.
        </p>
        <p>
          2. Sprzedawca zastrzega sobie prawo do zawieszenia lub zakończenia
          świadczenia poszczególnych funkcjonalności Serwisu internetowego z
          uwagi na konieczność konserwacji, przeglądu lub rozbudowy bazy
          technicznej bądź oprogramowania. Zawieszenie bądź zakończenie
          świadczeń poszczególnych funkcjonalności Serwisu internetowego nie
          może naruszać praw Klienta.
        </p>
        <p className={styles.center}>
          <strong>&sect; 10</strong>
        </p>
        <p className={styles.center}>
          <strong>Prawa Konsumenta</strong>
        </p>
        <p>
          1. Szczegółowe informacje dotyczące możliwości skorzystania przez
          Konsumenta z pozasądowych sposobów rozpatrywania reklamacji i
          dochodzenia roszczeń oraz zasady dostępu do tych procedur dostępne są
          w siedzibach oraz na stronach internetowych powiatowych (miejskich)
          rzeczników konsumentów, organizacji społecznych, do których zadań
          statutowych należy ochrona konsumentów, Wojewódzkich Inspektoratów
          Inspekcji Handlowej oraz pod następującymi adresami internetowymi
          Urzędu Ochrony Konkurencji i Konsumentów:
        </p>
        <ul>
          <li>
            https://www.uokik.gov.pl/pozasadowe_rozwiazywanie_sporow_konsumenckich.php
          </li>
          <li>https://www.uokik.gov.pl/sprawy_indywidualne.php</li>
          <li>http://www.uokik.gov.pl/wazne_adresy.php.</li>
        </ul>
        <p>
          2. Konsument posiada następujące przykładowe możliwości skorzystania z
          pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń:
        </p>
        <p>
          3. Konsument uprawniony jest do zwrócenia się do stałego polubownego
          sądu konsumenckiego, o którym mowa w art. 37 ustawy z dnia 15 grudnia
          2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z późn. zm.),
          z wnioskiem o rozstrzygnięcie sporu wynikłego z Umowy zawartej ze
          Sprzedawcą.
        </p>
        <p>
          4. Konsument uprawniony jest do zwrócenia się do wojewódzkiego
          inspektora Inspekcji Handlowej, zgodnie z art. 36 ustawy z dnia 15
          grudnia 2000 r. o Inspekcji Handlowej (Dz.U. z 2014 r. poz. 148 z
          późn. zm.), z wnioskiem o wszczęcie postępowania mediacyjnego w
          sprawie polubownego zakończenia sporu między Konsumentem a Sprzedawcą.
        </p>
        <p>
          5. Konsument może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia
          sporu między nim a Sprzedawcą, korzystając także z bezpłatnej pomocy
          powiatowego (miejskiego) rzecznika konsumentów lub organizacji
          społecznej, do której zadań statutowych należy ochrona konsumentów
          (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).
        </p>
        <p>
          6. może uzyskać bezpłatną pomoc w sprawie rozstrzygnięcia sporu między
          Klientem a Sprzedawcą, korzystając także z bezpłatnej pomocy
          powiatowego (miejskiego) rzecznika konsumentów lub organizacji
          społecznej, do której zadań statutowych należy ochrona Konsumentów
          (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).
          Porady udzielane są pod przez Federację Konsumentów pod bezpłatnym
          numerem infolinii konsumenckiej 800 007 707
        </p>
        <p className={styles.center}>
          <strong>&sect; 11</strong>
        </p>
        <p className={styles.center}>
          <strong>Dane Osobowe</strong>
        </p>
        <p>
          1. Administratorem danych osobowych Klientów zbieranych za
          pośrednictwem Serwisu Internetowego jest Sprzedawca.
        </p>
        <p>
          2. Dane osobowe Klientów zbierane przez administratora za
          pośrednictwem Serwisu Internetowego zbierane są w celu realizacji
          Umowy Sprzedaży, a jeżeli Klient wyrazi na to osobną zgodę - także w
          celu marketingowym.
        </p>
        <p>
          3. Klient ma prawo dostępu do treści swoich danych oraz ich
          poprawiania.
        </p>
        <p>
          4. Podanie danych osobowych jest dobrowolne, aczkolwiek niepodanie
          wskazanych w Regulaminie danych osobowych niezbędnych do zawarcia
          Umowy Sprzedaży skutkuje brakiem możliwości zawarcia tejże umowy.
        </p>
        <p>
          5. Szczegółowe sposoby kategorii danych oraz sposobu ich wykorzystania
          są opisane w Polityce Prywatności cateringu
        </p>
        <p className={styles.center}>
          <strong>&sect; 12</strong>
        </p>
        <p className={styles.center}>
          <strong>Prawa Autorskie</strong>
        </p>
        <p>
          1. Prawa autorskie oraz prawa własności intelektualnej do Serwisu
          Internetowego jako całości oraz jego poszczególnych elementów, w tym
          grafik, utworów, wzorów i znaków dostępnych w jego ramach należą do
          Sprzedawcy lub innych uprawnionych podmiotów trzecich i objęte są
          ochroną Prawa Autorskiego oraz innych przepisów powszechnie
          obowiązującego prawa. Ochrona przyznana Serwisowi Internetowemu
          obejmuje wszystkie formy ich wyrażenia.
        </p>
        <p>
          2. Serwis Internetowy należy traktować podobnie jak każdy inny utwór
          podlegający ochronie autorskiej. Klient nie ma prawa kopiować Serwisu
          Internetowego, za wyjątkiem przypadków dozwolonych przepisami
          bezwzględnie wiążącego prawa. Klient zobowiązuje się także nie
          modyfikować, adaptować, tłumaczyć, odkodowywać, dekompilować,
          dezasemblować lub w jakikolwiek inny sposób starać się ustalić kod
          źródłowy Serwisu Internetowego, za wyjątkiem przypadków dozwolonych
          przepisami bezwzględnie wiążącego prawa.
        </p>
        <p>
          3. Znaki handlowe Sprzedawcy oraz podmiotów trzecich powinny być
          używane zgodnie z obowiązującymi przepisami prawa.
        </p>
        <p className={styles.center}>
          <strong>&sect; 13</strong>
        </p>
        <p className={styles.center}>
          <strong>Postanowienia Końcowe</strong>
        </p>
        <p>
          1. Sprzedawca zastrzega sobie prawo do zmiany niniejszego Regulaminu.
          O zmianie Regulaminu Sprzedawca powiadomi na stronie Serwisu
          internetowego na co najmniej 7 dni kalendarzowych przed wejściem w
          życie zmian w Regulaminie. Zmiana postanowień Regulaminu nie ma
          zastosowania do Klientów, którzy złożyli zamówienie w czasie
          obowiązywania poprzedniej wersji Regulaminu.
        </p>
        <p>
          2. W pozostałych kwestiach nieuregulowanych zapisami niniejszego
          Regulaminu mają zastosowanie odpowiednie przepisy prawa polskiego.
        </p>
        <p>3. Regulamin wchodzi w życie z dniem 08.10.2021r.</p>
      </div>
    </section>
  );
};
