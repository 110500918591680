import React, { useContext } from 'react';
import { IconFb } from 'components/molecules/IconFb/IconFb';
import { IconInstagram } from 'components/molecules/IconInstagram/IconInstagram';
import { NavigationContext } from 'providers/NavigationProvider';
import { NavigationListItem } from 'components/atoms/NavigationListItem/NavigationListItem';
import { Button } from 'components/atoms/Button/Button';

import styles from 'components/molecules/NavigationListItems/NavigationListItems.module.scss';
export const NavigationListItems = () => {
  const context = useContext(NavigationContext);

  return (
    <div className={styles.container}>
      <ul className={styles.listItemWrapper}>
        <NavigationListItem
          title="diety"
          link="/diety/slim&fit"
          onClick={() => {
            if (context.size[0] <= 1200) {
              context.handleToggleMobileNavigation();
            }
            context.handleNavigationListItemIndex(1);
            context.handleScrollTo();
          }}
          bg={context.navigationListItemIndex === 1 ? '#e9b993' : 'transparent'}
        />
        <NavigationListItem
          title="menu"
          link="/menu"
          onClick={() => {
            if (context.size[0] <= 1200) {
              context.handleToggleMobileNavigation();
            }
            context.handleNavigationListItemIndex(2);
            context.handleScrollTo();
          }}
          bg={context.navigationListItemIndex === 2 ? '#e9b993' : 'transparent'}
        />

        {/* <a
          className={styles.listItem}
          href="https://menu.dailycious.pl/menu/"
          rel="noreferrer"
        >
          <div className={styles.listItem__fillDivTransparent}></div>
          <li>menu</li>
        </a> */}
        <NavigationListItem
          title="cennik"
          link="/cennik"
          onClick={() => {
            if (context.size[0] <= 1200) {
              context.handleToggleMobileNavigation();
            }
            context.handleNavigationListItemIndex(3);
            context.handleScrollTo();
          }}
          bg={context.navigationListItemIndex === 3 ? '#e9b993' : 'transparent'}
        />
        <NavigationListItem
          title="promocje"
          link="/promocje"
          onClick={() => {
            if (context.size[0] <= 1200) {
              context.handleToggleMobileNavigation();
            }
            context.handleNavigationListItemIndex(4);
            context.handleScrollTo();
          }}
          bg={context.navigationListItemIndex === 4 ? '#e9b993' : 'transparent'}
        />
        <NavigationListItem
          title="dostawa"
          link="/dostawa"
          onClick={() => {
            if (context.size[0] <= 1200) {
              context.handleToggleMobileNavigation();
            }
            context.handleNavigationListItemIndex(5);
            context.handleScrollTo();
          }}
          bg={context.navigationListItemIndex === 5 ? '#e9b993' : 'transparent'}
        />
        <NavigationListItem
          title="kontakt"
          link="/kontakt"
          onClick={() => {
            if (context.size[0] <= 1200) {
              context.handleToggleMobileNavigation();
            }
            context.handleNavigationListItemIndex(6);
            context.handleScrollTo();
          }}
          bg={context.navigationListItemIndex === 6 ? '#e9b993' : 'transparent'}
        />
      </ul>

      <ul className={styles.listItemWrapperLeft}>
        <div className={styles.buttonsWrapper}>
          <a
            className={styles.listItemButton}
            href="https://panel.dietly.pl/logowanie"
            rel="noreferrer"
          >
            <Button
              text="panel klienta"
              backgroundColor="white"
              border="2px solid #f3e9d9"
            />
          </a>

          <a
            className={styles.listItemButton}
            href="https://zamow.dailycious.pl/zamowienie/#/"
            rel="noreferrer"
          >
            <Button text="zamów online" backgroundColor="#ffb526" />
          </a>
        </div>

        <ul className={styles.iconsWrapper}>
          <li className={styles.listItemIcon}>
            <IconFb />
          </li>
          <li className={styles.listItemIcon}>
            <IconInstagram />
          </li>
        </ul>
      </ul>
    </div>
  );
};
