import React from 'react';
import iconinstawhite from 'assets/icons/icon-instagram-white.svg';

export const IconInstagramWhite = () => {
  return (
    <a
      href="https://www.instagram.com/dailycious_catering/"
      target="_blank"
      rel="noreferrer"
    >
      <img src={iconinstawhite} alt="logoinsta"></img>
    </a>
  );
};
