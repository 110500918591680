import React, { useContext } from 'react';
import { HomePageSlideWrapper } from 'components/organisms/HomePageSlideWrapper/HomePageSlideWrapper';
import { HomePagePhoto } from 'components/organisms/HomePagePhoto/HomePagePhoto';
import { HomePageHowWork } from 'components/organisms/HomePageHowWork/HomePageHowWork';
import { DietlyWidget } from 'components/atoms/DietlyWidget/DietlyWidget';
import { NavigationContext } from 'providers/NavigationProvider';
import { PromotionBanner } from 'components/organisms/PromotionBanner/PromotionBanner';
import { Helmet } from 'react-helmet-async';

import styles from 'components/templates/HomePage/HomePage.module.scss';

export const HomePage = () => {
  const context = useContext(NavigationContext);
  return (
    <section className={styles.container}>
      <Helmet>
        <title>Dailycious | Catering dietetyczny | Dieta pudełkowa</title>
      </Helmet>
      {context.openBanner || !context.banner ? null : <PromotionBanner />}
      <HomePageSlideWrapper />
      <HomePagePhoto />
      <HomePageHowWork />
      <DietlyWidget />
    </section>
  );
};
