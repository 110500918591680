import React, { useState } from 'react';
import { cities } from 'data/Cites';

export const DeliveryCitiesPageContext = React.createContext({
  handleInputChange: () => {},
  citesList: [],
  setCitiesList: () => {},
});

export const DeliveryCitiesPageProvider = ({ children }) => {
  const [citiesList, setCitiesList] = useState([]);

  //Function that checks if the value of the input is found in the list of cities and sets the city to be displayed
  const filteringCities = (valueInput) => {
    const threeCharactersValuesCity = valueInput.substr(0, 3).toLowerCase();

    const filteredCities = cities.filter((city) =>
      city.substr(0, 3).toLowerCase().includes(threeCharactersValuesCity)
    );

    if (valueInput.length <= 1) {
      setCitiesList([]);
    } else {
      setCitiesList(filteredCities);
    }
  };

  //Handle to capture the value of input
  const handleInputChange = (e) => {
    filteringCities(e.target.value);
  };

  return (
    <DeliveryCitiesPageContext.Provider
      value={{
        handleInputChange,
        citiesList,
        setCitiesList,
      }}
    >
      {children}
    </DeliveryCitiesPageContext.Provider>
  );
};
