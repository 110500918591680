import React, { useContext } from 'react';
import { PricesPageContext } from 'providers/PricesPageProvider';
import buttonvectordown from 'assets/icons/buttonvectordown.png';

import styles from 'components/molecules/PricesPageChooseDietButton/PricesPageChooseDietButton.module.scss';
export const PricesPageChooseDietButton = () => {
  const context = useContext(PricesPageContext);
  return (
    <button
      onClick={() => context.handleToggleDropdown()}
      className={styles.button}
    >
      <p className={styles.p}>{context.currentDiet.title}</p>
      <img src={buttonvectordown} alt="icon"></img>
    </button>
  );
};
