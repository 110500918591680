import React from 'react';
import PropTypes from 'prop-types';

import logofb from 'assets/icons/icon-facebook.svg';
import styles from 'components/molecules/IconFb/IconFb.module.scss';
export const IconFb = ({ width, height, text }) => {
  return (
    <a
      className={styles.wrapper}
      href="https://www.facebook.com/dailyciouscatering"
      target="_blank"
      rel="noreferrer"
    >
      <img width={width} height={height} src={logofb} alt="logofb"></img>
      <p className={styles.wrapper__p}>{text}</p>
    </a>
  );
};

IconFb.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  text: PropTypes.string,
};
