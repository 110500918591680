import React, { useState, useEffect } from 'react';
import { diets } from 'data/Diets';

export const PricesPageContext = React.createContext({
  currentDiet: {},
  setCurrentDiet: () => {},
  dietPrice: '',
  setDietPrice: () => {},
  days: '',
  setDays: () => {},
  totalPrice: '',
  setTotalPrice: () => {},
  index: '',
  setIndex: () => {},
  isOpen: '',
  setIsOpen: () => {},
  handleInputChange: () => {},
  handleShowDietPrice: () => {},
  handleShowDiet: () => {},
  handleToggleDropdown: () => {},
  check10Days: '',
  setCheck10Days: () => {},
  check20Days: '',
  setCheck20Days: () => {},
  check30Days: '',
  setCheck30Days: () => {},
  savingMoney: '',
  setSavingMoney: () => {},
  calculatedPrice: '',
  setCalculatedPrice: () => {},
});

const initialState = {
  title: diets[0].title,
  image: diets[0].image,
  background: diets[0].background,
  kcalPriceInfo: diets[0].kcalPriceInfo,
  promotion: diets[0].promotion,
};

export const PricesPageProvider = ({ children }) => {
  const [currentDiet, setCurrentDiet] = useState(initialState);
  const [dietPrice, setDietPrice] = useState(
    currentDiet.kcalPriceInfo[0].price
  );
  const [days, setDays] = useState(21);
  const [totalPrice, setTotalPrice] = useState(0);
  const [index, setIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [check10Days, setCheck10Days] = useState(false);
  const [check20Days, setCheck20Days] = useState(false);
  const [check30Days, setCheck30Days] = useState(false);
  const [savingMoney, setSavingMoney] = useState(0);
  const [calculatedPrice, setCalculatedPrice] = useState('');

  useEffect(() => {
    setTotalPrice(days * calculatedPrice);
  }, [days, calculatedPrice]);

  useEffect(() => {
    if (days < 10) {
      setSavingMoney(0);
      setTotalPrice(dietPrice * days);
    }

    if (days >= 10) {
      setCheck10Days(true);
      setCalculatedPrice(dietPrice - 1);
      setSavingMoney(days);
    } else setCheck10Days(false);
  }, [days, dietPrice]);

  useEffect(() => {
    if (days >= 20) {
      setCheck20Days(true);
      setCalculatedPrice(dietPrice - 2);
      setSavingMoney(days * 2);
    } else setCheck20Days(false);
  }, [days, dietPrice]);

  useEffect(() => {
    if (days >= 30) {
      setCheck30Days(true);
      setCalculatedPrice(dietPrice - 3);
      setSavingMoney(days * 3);
    } else setCheck30Days(false);
  }, [days, dietPrice]);

  //Handle to capture the value of input
  const handleInputChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setDays(value);
  };

  //Handle to captures price and index of each mapped diet
  const handleShowDietPrice = (price, index) => {
    setDietPrice(price);
    setIndex(index);
  };

  //Handle to captures the props of each mapped diet
  const handleShowDiet = (title, image, background, kcalPriceInfo) => {
    const newDietItem = {
      title,
      image,
      background,
      kcalPriceInfo,
    };
    const initialPrice = newDietItem.kcalPriceInfo.find((diet) => {
      return diet.kcal >= 900;
    });
    setDietPrice(initialPrice.price);
    setCurrentDiet(newDietItem);
    setIndex(0);
    setIsOpen(!isOpen);
  };

  //Handle to toggle open/close dropdown
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PricesPageContext.Provider
      value={{
        currentDiet,
        setCurrentDiet,
        dietPrice,
        setDietPrice,
        days,
        setDays,
        totalPrice,
        setTotalPrice,
        index,
        setIndex,
        isOpen,
        setIsOpen,
        handleInputChange,
        handleShowDietPrice,
        handleShowDiet,
        handleToggleDropdown,
        check10Days,
        setCheck10Days,
        check20Days,
        setCheck20Days,
        check30Days,
        setCheck30Days,
        savingMoney,
        setSavingMoney,
        calculatedPrice,
        setCalculatedPrice,
      }}
    >
      {children}
    </PricesPageContext.Provider>
  );
};
