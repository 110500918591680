import React from 'react';
import iconfbwhite from 'assets/icons/icon-facebook-white.svg';

export const IconFbWhite = () => {
  return (
    <a
      href="https://www.facebook.com/dailyciouscatering"
      target="_blank"
      rel="noreferrer"
    >
      <img src={iconfbwhite} alt="logofb"></img>
    </a>
  );
};
