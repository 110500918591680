import React from 'react';
import { IconFbWhite } from '../IconFbWhite/IconFbWhite';
import { IconInstagramWhite } from '../IconInstagramWhite/IconInstagramWhite';
import logoDailyCiousWhite from 'assets/icons/logo-dailycious-white.svg';

import styles from 'components/molecules/FooterContacts/FooterContacts.module.scss';
export const FooterContacts = () => {
  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logoDailyCiousWhite} alt="logo"></img>
      <div className={styles.wrapperContacts}>
        {/* <p className={styles.contactsText}>Dailycious</p> */}
        <p className={styles.contactsText}>
          +48 690 549 699
          <br />
          pon. - pt.: 10:00 - 18:00
          <br />
          kontakt@dailycious.pl
        </p>
        <ul className={styles.iconsWrapper}>
          <li className={styles.listItem}>
            <IconFbWhite />
          </li>
          <li className={styles.listItem}>
            <IconInstagramWhite />
          </li>
        </ul>
      </div>
    </div>
  );
};
