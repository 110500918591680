import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/molecules/DietsPageInfoPhotoItem/DietsPageInfoPhotoItem.module.scss';
export const DietsPageInfoPhotoItem = ({ background, image }) => {
  return (
    <div className={styles.container}>
      <img className={styles.background} src={background} alt="dietimage"></img>
      <img className={styles.dietImage} src={image} alt="dietimage"></img>
    </div>
  );
};

DietsPageInfoPhotoItem.propTypes = {
  background: PropTypes.string,
  image: PropTypes.string,
};
