import React from 'react';

import PropTypes from 'prop-types';

import styles from 'components/atoms/Button/Button.module.scss';
export const Button = ({ text, backgroundColor, border, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ background: backgroundColor, border: border }}
      className={styles.button}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  onClick: PropTypes.func,
};
