import React, { useContext } from 'react';
import { PricesPageContext } from 'providers/PricesPageProvider';
import { Button } from 'components/atoms/Button/Button';

import styles from 'components/organisms/PricesPageTotalPriceWrapper/PricesPageTotalPriceWrapper.module.scss';
export const PricesPageTotalPriceWrapper = () => {
  const context = useContext(PricesPageContext);
  return (
    <div className={styles.footer}>
      <div className={styles.footerDiv}>
        <p className={styles.footerDiv__p}>koszt całkowity</p>
        <p className={styles.fontBeige}>{context.totalPrice},00 zł</p>
      </div>
      <div className={styles.footerDivWithButton}>
        <p className={styles.p}>dostawa GRATIS</p>
        <a href="https://zamow.dailycious.pl/zamowienie/#/" rel="noreferrer">
          <Button text="zamów online" backgroundColor={'#ffb526'} />
        </a>
      </div>
    </div>
  );
};
