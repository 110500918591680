export const cities = [
  'Bielawa',
  'Chylice',
  'Duchnic',
  'Dziekanów',
  'Jabłonna',
  'Janki',
  'Józefosław',
  'Józefów',
  'Julianów',
  'Kiełpin',
  'Kobyłka',
  'Komorów',
  'Konstancin',
  'Legionowo',
  'Lesznowola',
  'Łomianki',
  'Marki',
  'Mościcka',
  'Mysiadło',
  'Nowa Iwiczna',
  'Otwock',
  'Ożarów Mazowiecki',
  'Piaseczno',
  'Piastów',
  'Pruszków',
  'Raszyn',
  'Rembelszczyzna',
  'Sękocin',
  'Stare Babice',
  'Warszawa',
  'Wesoła',
  'Zakręt',
  'Ząbki',
  'Zgorzała',
  'Zielonka',
];
