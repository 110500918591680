import React from 'react';
import homepagephoto from 'assets/strona glowna/photo-o-nas.jpg';

import styles from 'components/organisms/HomePagePhoto/HomePagePhoto.module.scss';
export const HomePagePhoto = () => {
  return (
    <div className={styles.container}>
      <img className={styles.img} src={homepagephoto} alt="HomePagePhoto"></img>
      <div className={styles.textWrapper}>
        <p className={styles.textHeader}>
          JESTEŚMY PO TO, ABY
          <br /> KAŻDY TWÓJ DZIEŃ
          <br /> SMAKOWAŁ
          <span className={styles.span}> WSPANIALE</span>
        </p>
        <p className={styles.textFooter}>
          Jakość, różnorodność i smak, to coś co wyróżnia nasze diety i sprawia,
          że podbijają one nie tylko podniebienia, ale i serca nawet najbardziej
          wymagających smakoszy. Naszą miłość do jedzenia i pasję do gotowania
          widać w każdym posiłku, który przygotowujemy z niezwykłą starannością
          i dbałością nawet o najdrobniejsze szczegóły. Nad naszym menu czuwa
          grono wykwalifikowanych dietetyków, bo wiemy, że codzienna dieta ma
          być nie tylko smaczna, ale i zdrowa. Odpowiedni bilans
          makroskładników, produkty najwyższej jakości i urozmaicone posiłki to
          nasz przepis na radość z jedzenia każdego dnia!
        </p>
      </div>
    </div>
  );
};
