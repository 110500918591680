import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FooterNavigationDietItem } from 'components/atoms/FooterNavigationDietItem/FooterNavigationDietItem';
import { NavigationContext } from 'providers/NavigationProvider';
import { diets } from 'data/Diets';

import styles from 'components/molecules/FooterNavigation/FooterNavigation.module.scss';
export const FooterNavigation = () => {
  const navContext = useContext(NavigationContext);
  return (
    <section className={styles.container}>
      <ul className={styles.wrapper}>
        <h2 className={styles.textHeader}>Nasze diety</h2>
        {diets.map(({ title }) => (
          <FooterNavigationDietItem
            key={title}
            to={`/diety/${title.toLowerCase().replace(/\s/g, '')}`}
            title={title}
            onClick={() => {
              navContext.handleNavigationListItemIndex(1);
              navContext.handleScrollTo();
            }}
          />
        ))}
      </ul>

      <ul className={styles.wrapper}>
        <h2 className={styles.textHeader}>Mapa strony</h2>
        <Link
          onClick={() => {
            navContext.handleNavigationListItemIndex(0);
            navContext.handleScrollTo();
          }}
          to="/"
        >
          <p className={styles.textList}>strona główna</p>
        </Link>
        <Link
          onClick={() => {
            navContext.handleNavigationListItemIndex(1);
            navContext.handleScrollTo();
          }}
          to="/diety/slim&fit"
        >
          <p className={styles.textList}>diety</p>
        </Link>
        <Link
          onClick={() => {
            navContext.handleNavigationListItemIndex(2);
            navContext.handleScrollTo();
          }}
          to="/menu"
        >
          <p className={styles.textList}>menu</p>
        </Link>

        <Link
          onClick={() => {
            navContext.handleNavigationListItemIndex(3);
            navContext.handleScrollTo();
          }}
          to="/cennik"
        >
          <p className={styles.textList}>cennnik</p>
        </Link>
        <Link
          onClick={() => {
            navContext.handleNavigationListItemIndex(4);
            navContext.handleScrollTo();
          }}
          to="/promocje"
        >
          <p className={styles.textList}>promocje</p>
        </Link>
        <Link
          onClick={() => {
            navContext.handleNavigationListItemIndex(5);
            navContext.handleScrollTo();
          }}
          to="/dostawa"
        >
          <p className={styles.textList}>dostawa</p>
        </Link>
        <Link
          onClick={() => {
            navContext.handleScrollTo();
            navContext.handleNavigationListItemIndex(0);
          }}
          to="/polityka-prywatnosci"
        >
          <p className={styles.textList}>polityka prywatności</p>
        </Link>

        <Link
          onClick={() => {
            navContext.handleScrollTo();
            navContext.handleNavigationListItemIndex(0);
          }}
          to="/regulamin"
        >
          <p className={styles.textList}>regulamin</p>
        </Link>
      </ul>

      <ul className={styles.wrapper}>
        <h2 className={styles.textHeader}>Zamówienia</h2>
        <Link
          onClick={() => {
            navContext.handleNavigationListItemIndex(6);
            navContext.handleScrollTo();
          }}
          to="/kontakt"
        >
          <p className={styles.textList}>kontakt</p>
        </Link>

        <a href="https://panel.dietly.pl/logowanie" rel="noreferrer">
          <p className={styles.textList}>panel klienta</p>
        </a>

        <a href="https://zamow.dailycious.pl/zamowienie/#/" rel="noreferrer">
          <p className={styles.textList}>zamów online</p>
        </a>
      </ul>
    </section>
  );
};
