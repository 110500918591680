//Diets photo&background
import standard from 'assets/zdjecia diet/standard.png';
import vege from 'assets/zdjecia diet/vege.png';
import vegefish from 'assets/zdjecia diet/vege+fish.png';
import sport from 'assets/zdjecia diet/sport.png';
import glutenlactosefree from 'assets/zdjecia diet/gluten&lactosefree.png';
import niskiig from 'assets/zdjecia diet/niski ig-1.png';
import slimfit from 'assets/zdjecia diet/slim&fit.png';
import bgstandard from 'assets/zdjecia diet/ksztalty-standard.svg';
import bgvege from 'assets/zdjecia diet/ksztalty-vege.svg';
import bgvegefish from 'assets/zdjecia diet/ksztalty-vege fish.svg';
import bgsport from 'assets/zdjecia diet/ksztalty-sport.svg';
import bgglutenlactosefree from 'assets/zdjecia diet/ksztalty-gluten lactose free.svg';
import bgniskiig from 'assets/zdjecia diet/ksztalty-niski ig-1.svg';
import bgslimfit from 'assets/zdjecia diet/ksztalty-slim&fit.svg';

//Icons
import dietazbilansowana from 'assets/icons/icon-zbilansowana dieta.svg';
import dietetycznybestseller from 'assets/icons/icon-dietetyczny bestseller.svg';
import skladnikiroslinne from 'assets/icons/icon-skladniki roslinne i nabial.svg';
import animalfirendly from 'assets/icons/icon-animal friendly.svg';
import wiecejbialka from 'assets/icons/icon-wiecej bialka.svg';
import duzailoscenergii from 'assets/icons/icon-duza ilosc energii.svg';
import obnizonycukier from 'assets/icons/icon-obnizona zawartosc cukru.png';
import wrazliwyuklad from 'assets/icons/icon-dla wrazliwego ukladu pokarmowego.svg';
import zerogluten from 'assets/icons/icon-zero nabialu zero glutenu.svg';
import ryby from 'assets/icons/icon-ryby.svg';
import czteryposilki from 'assets/icons/icon-4 posilki.png';

export const diets = [
  {
    title: 'SLIM & FIT',
    id: 1,
    image: slimfit,
    background: bgslimfit,
    icons: [
      {
        icon: dietazbilansowana,
        iconTitle: 'zbilansowane posiłki',
      },
      { icon: dietetycznybestseller, iconTitle: 'dietetyczny bestseller' },
    ],
    promotion: [{ 10: 1, 20: 1, 30: 1 }],
    kcalPriceInfo: [
      {
        kcal: 1000,
        price: 42,
      },
      {
        kcal: 1200,
        price: 46,
      },
      {
        kcal: 1500,
        price: 50,
      },
    ],
    description:
      'Dieta odchudzająca nie musi być nudna i monotonna, a utrata zbędnych kilogramów nie musi być drogą przez mękę! Dieta slim&fit to połączenie przyjemnego z pożytecznym, czyli smacznych i zbilansowanych posiłków z niższą kaloryką wspierającą redukcję masy ciała w zdrowym tempie. Menu zostało opracowane przez wykwalifikowanych dietetyków i składa się z 4 posiłków, które zadowolą nawet najbardziej wymagających smakoszy. Różnorodność dań, odpowiedni bilans makroskładników i wspaniały smak to przepis na radość z jedzenia każdego dnia!',
    protein: '20-25%',
    fats: '30-35%',
    carbohydrates: '45-50%',
  },
  {
    title: 'STANDARD',
    id: 2,
    image: standard,
    background: bgstandard,
    icons: [
      { icon: dietazbilansowana, iconTitle: 'dieta zbilansowana' },
      { icon: dietetycznybestseller, iconTitle: 'dietetyczny bestseller' },
    ],
    promotion: [{ 10: 1, 20: 1, 30: 1 }],
    kcalPriceInfo: [
      {
        kcal: 1200,
        price: 47,
      },
      {
        kcal: 1500,
        price: 49,
      },
      {
        kcal: 1800,
        price: 52,
      },

      {
        kcal: 2000,
        price: 54,
      },
      {
        kcal: 2500,
        price: 58,
      },
      {
        kcal: 3000,
        price: 60,
      },
      {
        kcal: 3500,
        price: 63,
      },
    ],
    description:
      'Naszym standardem są zbilansowane i różnorodne posiłki z najwyższej jakości naturalnych składników. Nie korzystamy z półproduktów i nie uznajemy półśrodków, dlatego dieta Standard to nieocenione wsparcie w utracie zbędnych kilogramów oraz wypracowaniu zdrowych nawyków żywieniowych. Odpowiedni bilans makroskładników, wspaniały smak i dbałość o niezmienną jakość naszych dań to przepis na radość z jedzenia każdego dnia!',
    protein: '20%',
    fats: '30-35%',
    carbohydrates: '45-50%',
  },
  {
    title: 'SPORT',
    id: 3,
    image: sport,
    background: bgsport,
    icons: [
      { icon: wiecejbialka, iconTitle: 'więcej białka' },
      { icon: duzailoscenergii, iconTitle: 'duża ilość energii' },
    ],
    promotion: [{ 10: 1, 20: 1, 30: 1 }],
    kcalPriceInfo: [
      {
        kcal: 1800,
        price: 54,
      },
      {
        kcal: 2000,
        price: 56,
      },
      {
        kcal: 2500,
        price: 59,
      },
      {
        kcal: 3000,
        price: 66,
      },
      {
        kcal: 3500,
        price: 70,
      },
    ],
    description:
      'Doskonale wiemy jak ważna jest odpowiednia dieta i dobrze skonstruowany plan żywieniowy przy wzmożonej aktywności fizycznej i intensywnych treningach. Dlatego też dietę sport stworzyliśmy tak, by nie tylko zawierała odpowiednią kaloryke i dostarczała niezbędną ilość energii, ale również wspierała regenerację mięśni i prawidłowe funkcjonowanie organizmu. Menu opracowane przez wykwalifikowanych dietetyków, odpowiedni bilans makroskładników i wspaniały smak to przepis na sukces każdego dnia!',
    protein: '20-25%',
    fats: '25-35%',
    carbohydrates: '40-55%',
  },

  {
    title: 'VEGE',
    id: 4,
    image: vege,
    background: bgvege,
    icons: [
      { icon: skladnikiroslinne, iconTitle: 'składniki roślinne' },
      { icon: animalfirendly, iconTitle: 'animal friendly' },
    ],
    promotion: [{ 10: 1, 20: 1, 30: 1 }],
    kcalPriceInfo: [
      {
        kcal: 1200,
        price: 47,
      },
      {
        kcal: 1500,
        price: 49,
      },
      {
        kcal: 1800,
        price: 52,
      },
      {
        kcal: 2000,
        price: 54,
      },
      {
        kcal: 2500,
        price: 58,
      },
      {
        kcal: 3000,
        price: 61,
      },
    ],
    description:
      'Nasza dieta wegetariańska to doskonałe połączenie różnorodnych produktów roślinnych, naturalnego nabiału oraz jaj, stanowiących cenne źródła białka i zdrowych tłuszczy, niezbędnych do prawidłowego funkcjonowania organizmu i dobrego samopoczucia. Codzienna porcja zbilansowanych posiłków, wspaniały smak i najwyższa jakość produktów, z których korzystają nasi kucharze, to przepis na radość z jedzenia każdego dnia!',
    protein: '15-20%',
    fats: '30-35%',
    carbohydrates: '45-55%',
  },
  {
    title: 'VEGE+FISH',
    id: 5,
    image: vegefish,
    background: bgvegefish,
    icons: [
      { icon: skladnikiroslinne, iconTitle: 'składniki roślinne' },
      { icon: ryby, iconTitle: 'ryby' },
    ],
    promotion: [{ 10: 1, 20: 1, 30: 1 }],
    kcalPriceInfo: [
      {
        kcal: 1200,
        price: 49,
      },
      {
        kcal: 1500,
        price: 51,
      },
      {
        kcal: 1800,
        price: 54,
      },
      {
        kcal: 2000,
        price: 56,
      },
      {
        kcal: 2500,
        price: 59,
      },
    ],
    description:
      'Nasza dieta Vege + Fish to doskonałe połączenie różnorodnych produktów roślinnych, naturalnego nabiału, jaj oraz ryb i owoców morza, stanowiących cenne źródła białka i zdrowych tłuszczy, niezbędnych do prawidłowego funkcjonowania organizmu i dobrego samopoczucia. Codzienna porcja zbilansowanych posiłków, wspaniały smak i najwyższa jakość produktów, z których korzystają nasi kucharze to przepis na radość z jedzenia każdego dnia!',
    protein: '20%',
    fats: '30-35%',
    carbohydrates: '45-50%',
  },
  {
    title: 'GLUTEN & LACTOSE FREE',
    id: 6,
    image: glutenlactosefree,
    background: bgglutenlactosefree,
    icons: [
      { icon: wrazliwyuklad, iconTitle: 'dla wrażliwego układu pokarmowego' },
      { icon: zerogluten, iconTitle: 'zero nabiału zero glutenu' },
    ],
    promotion: [{ 10: 1, 20: 1, 30: 1 }],
    kcalPriceInfo: [
      {
        kcal: 1200,
        price: 51,
      },
      {
        kcal: 1500,
        price: 53,
      },
      {
        kcal: 1800,
        price: 56,
      },
      {
        kcal: 2000,
        price: 58,
      },
      {
        kcal: 2500,
        price: 62,
      },
    ],
    description:
      'Dla wszystkich osób, które muszą, powinny lub po prostu chcą wykluczyć ze swojej diety nabiał i gluten mamy specjalną pozycję w naszym menu. Cały sekret tkwi w tym, by nie musieć rezygnować ze swoich ulubionych posiłków tylko umiejętnie zastąpić niepożądane produkty ich zdrowymi i bezpiecznymi dla wrażliwego układu pokarmowego zamiennikami. Różnorodność produktów, pomysłowe przepisy i wspaniały smak to radość z jedzenia każdego dnia!',
    protein: '20%',
    fats: '30-35%',
    carbohydrates: '45-50%',
  },
  {
    title: 'NISKI IG',
    id: 7,
    image: niskiig,
    background: bgniskiig,
    icons: [
      {
        icon: czteryposilki,
        iconTitle: 'cztery posiłki',
      },
      { icon: obnizonycukier, iconTitle: 'obniżona zawartość cukru' },
    ],
    promotion: [{ 10: 1, 20: 1, 30: 1 }],
    kcalPriceInfo: [
      {
        kcal: 1200,
        price: 51,
      },
      {
        kcal: 1500,
        price: 52,
      },
      {
        kcal: 1800,
        price: 54,
      },
      {
        kcal: 2000,
        price: 57,
      },
      {
        kcal: 2500,
        price: 60,
      },
    ],
    description:
      'Niski IG, czyli dieta składająca się z posiłków o obniżonej zawartości cukru to idealne rozwiązanie dla osób chcących ograniczyć wahania lub gwałtowne wzrosty poziomu glukozy we krwi. W codziennym menu znajdziemy zatem produkty z niskim i średnim indeksem glikemicznym, czyli warzywa, owoce, mięso, jaja i nabiał, a także pełnoziarniste dodatki, dzięki czemu możemy nadal spożywać ulubione potrawy zastępując niepożądane produkty ich zamiennikami z niższym IG. Menu opracowane przez wykwalifikowanych dietetyków, odpowiedni bilans makroskładników i wspaniały smak to przepis na radość z jedzenia każdego dnia!',
    protein: '20-25%',
    fats: '30-40%',
    carbohydrates: '35-45%',
  },
];
