import React, { useContext } from 'react';
import { NavigationContext } from 'providers/NavigationProvider';
import { NavigationListItems } from 'components/molecules/NavigationListItems/NavigationListItems';
import { Link } from 'react-router-dom';

import logodailycious from 'assets/icons/logo-dailycious.svg';
import styles from 'components/organisms/Navigation/Navigation.module.scss';
export const Navigation = () => {
  const context = useContext(NavigationContext);

  return (
    <nav
      className={
        context.windowScroll ? styles.containerScroll : styles.container
      }
    >
      {context.openMobileNavigation === true || context.size[0] > 1200 ? (
        <NavigationListItems />
      ) : null}

      <Link
        onClick={() => {
          context.handleScrollTo();
          context.handleNavigationListItemIndex(0);
          if (context.openMobileNavigation === true) {
            context.handleToggleMobileNavigation();
          }
        }}
        to="/"
      >
        <img
          className={styles.logo}
          src={logodailycious}
          alt="logodailtcious"
        ></img>
      </Link>

      <button
        onClick={() => context.handleToggleMobileNavigation()}
        className={styles.mobileNavToogle}
      >
        {context.openMobileNavigation ? (
          <span className={styles.mobileNavToogle__second}></span>
        ) : (
          <span className={styles.mobileNavToogle__first}></span>
        )}
      </button>
    </nav>
  );
};
