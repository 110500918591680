import React from 'react';
import { IconField } from 'components/molecules/IconField/IconField';

import icon1 from 'assets/icons/icon-1.svg';
import icon2 from 'assets/icons/icon-2.svg';
import icon3 from 'assets/icons/icon-3.svg';
import icon4 from 'assets/icons/icon-4.svg';

import styles from 'components/organisms/HomePageHowWork/HomePageHowWork.module.scss';
export const HomePageHowWork = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.textHeader}>A DZIAŁA TO TAK</h1>
      <div className={styles.iconsWrapper}>
        <IconField
          style={{ width: '320px', height: '320px' }}
          icon={icon1}
          text={
            <p className={styles.iconsWrapper__text}>
              Wybierasz jedną z pysznych diet
            </p>
          }
        />
        <IconField
          style={{ width: '320px', height: '320px' }}
          icon={icon2}
          text={
            <p className={styles.iconsWrapper__text}>
              Składasz zamówienie online
            </p>
          }
        />
        <IconField
          style={{ width: '320px', height: '320px' }}
          icon={icon3}
          text={
            <p className={styles.iconsWrapper__text}>
              Pyszna paczka jest pod drzwiami
            </p>
          }
        />
        <IconField
          style={{ width: '320px', height: '320px' }}
          icon={icon4}
          text={
            <p className={styles.iconsWrapper__text}>
              Cieszysz się tym co dobre
            </p>
          }
        />
      </div>
    </div>
  );
};
