import React, { useContext } from 'react';
import { PricesPageSlider } from '../PricesPageSlider/PricesPageSlider';
import { PricesPageContext } from 'providers/PricesPageProvider';

import styles from 'components/organisms/PricesPageSliderWrapper/PricesPageSliderWrapper.module.scss';
export const PricesPageSliderWrapper = () => {
  const context = useContext(PricesPageContext);
  return (
    <div className={styles.sliderDays}>
      <div className={styles.sliderWrapper}>
        <p>ilość dni</p>
        <p>{context.days} dni</p>
      </div>
      <PricesPageSlider
        min={1}
        max={90}
        step={1}
        value={context.days}
        onChangeValue={context.handleInputChange}
      />
    </div>
  );
};
