import React from 'react';
import { DietsPageHeader } from 'components/organisms/DietsPageHeader/DietsPageHeader';
import { DietsPageInfoWrapper } from 'components/organisms/DietsPageInfoWrapper/DietsPageInfoWrapper';
import { Helmet } from 'react-helmet-async';
import { DietsPageIconsWrapper } from 'components/organisms/DietsPageIconsWrapper/DietsPageIconsWrapper';
import { diets } from 'data/Diets';
import { useParams } from 'react-router-dom';

import styles from 'components/templates/DietsPage/DietsPage.module.scss';
export const DietsPage = () => {
  const { title } = useParams();
  const currentDietArray = diets.filter(
    (diet) => diet.title.toLowerCase().replace(/\s/g, '') === title
  );
  return (
    <section className={styles.container}>
      <Helmet>
        <title>Dailycious | Diety</title>
        <meta name="description" content="" />
      </Helmet>
      <DietsPageHeader />
      {currentDietArray.map(
        ({
          title,
          kcalPriceInfo,
          description,
          icons,
          background,
          image,
          id,
        }) => (
          <DietsPageInfoWrapper
            key={id}
            title={title}
            kcalPriceInfo={kcalPriceInfo}
            description={description}
            icons={icons}
            background={background}
            image={image}
          />
        )
      )}
      ,
      {currentDietArray.map(({ protein, fats, carbohydrates }) => (
        <DietsPageIconsWrapper
          key={protein}
          protein={protein}
          fats={fats}
          carbohydrates={carbohydrates}
        />
      ))}
    </section>
  );
};
