import React from 'react';
import PropTypes from 'prop-types';

import logoinsta from 'assets/icons/icon-instagram.svg';
import styles from 'components/molecules/IconInstagram/IconInstagram.module.scss';
export const IconInstagram = ({ width, height, text }) => {
  return (
    <a
      className={styles.wrapper}
      href="https://www.instagram.com/dailycious_catering/"
      target="_blank"
      rel="noreferrer"
    >
      <img width={width} height={height} src={logoinsta} alt="logoinsta"></img>
      <p className={styles.wrapper__p}>{text}</p>
    </a>
  );
};

IconInstagram.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  text: PropTypes.string,
};
