import React, { useContext } from 'react';
import { PricesPageContext } from 'providers/PricesPageProvider';

import styles from 'components/organisms/PricesPageDailyCostWrapper/PricesPageDailyCostWrapper.module.scss';
export const PricesPageDailyCostWrapper = () => {
  const context = useContext(PricesPageContext);
  return (
    <div className={styles.dailyCost}>
      <div className={styles.footerDiv}>
        <p>koszt dzienny</p>
        <p className={styles.fontBeige}>
          {context.check10Days || context.check20Days || context.check30Days
            ? context.calculatedPrice
            : context.dietPrice}
          ,00 zł
        </p>
      </div>
      <div className={styles.footerDiv}>
        <p>oszczędzasz</p>
        <p>{context.savingMoney},00 zł</p>
      </div>
    </div>
  );
};
