import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { HomePageContext } from 'providers/HomePageProvider';

import styles from 'components/molecules/HomePageSlideRightItem/HomePageSlideRightItem.module.scss';
export const HomePageSlideRightItem = ({ diets, currentSlideRightItem }) => {
  const context = useContext(HomePageContext);
  const ref = useRef(null);

  useEffect(() => {
    if (context.animationStart === false) return;
    if (context.animationActive) {
      ref.current.classList.add(styles.prevAnimation);
    } else {
      ref.current.classList.add(styles.nextAnimation);
    }
  }, [context]);

  return (
    <section className={styles.wrapperright}>
      {diets.map((diet, index) => {
        return (
          index === currentSlideRightItem && (
            <img
              ref={ref}
              className={styles.img}
              src={diet.image}
              key={index}
              alt="dietphoto"
            ></img>
          )
        );
      })}
    </section>
  );
};

HomePageContext.propTypes = {
  diets: PropTypes.array,
  currentSlideRightItem: PropTypes.number,
};
