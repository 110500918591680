import React, { useContext } from 'react';
import { DeliveryCitiesPageModal } from '../DeliveryCitiesPageModal/DeliveryCitiesPageModal';
import { DeliveryCitiesPageContext } from 'providers/DeliveryCitiesPageProvider';

import styles from 'components/molecules/DeliveryCitiesPageInputField/DeliveryCitiesPageInputField.module.scss';
export const DeliveryCitiesPageInputField = () => {
  const context = useContext(DeliveryCitiesPageContext);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>WARSZAWA I OKOLICE</h1>
      <div className={styles.wrapper}>
        <input
          autoComplete="off"
          name="city"
          className={styles.input}
          placeholder="Wpisz nazwę miejscowości"
          type="search"
          onChange={context.handleInputChange}
        />
        {context.citiesList?.map((city) => (
          <DeliveryCitiesPageModal city={city} key={city} />
        ))}
      </div>
    </div>
  );
};
