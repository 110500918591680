import React from 'react';
import { Helmet } from 'react-helmet-async';

import styles from 'components/templates/MenuPage/MenuPage.module.scss';
export const MenuPage = () => {
  return (
    <>
      <Helmet>
        <title>Dailycious | Menu</title>
        <meta name="description" content="" />
      </Helmet>
      <div className={styles.container}>
        <iframe
          scrolling="no"
          className={styles.iframe}
          title="menu"
          src="https://menu.dailycious.pl"
        ></iframe>
      </div>
    </>
  );
};
