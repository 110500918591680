import React from 'react';
import { DietsPageInfoPhotoItem } from 'components/molecules/DietsPageInfoPhotoItem/DietsPageInfoPhotoItem';
import { DietsPageInfoDietItem } from '../DietsPageInfoDietItem/DietsPageInfoDietItem';

import styles from 'components/organisms/DietsPageInfoWrapper/DietsPageInfoWrapper.module.scss';

export const DietsPageInfoWrapper = ({
  title,
  kcalPriceInfo,
  description,
  icons,
  background,
  image,
}) => {
  return (
    <div className={styles.container}>
      <DietsPageInfoDietItem
        title={title}
        kcalPriceInfo={kcalPriceInfo}
        description={description}
        icons={icons}
      />
      <div className={styles.wrapper}>
        <DietsPageInfoPhotoItem background={background} image={image} />
      </div>
    </div>
  );
};
