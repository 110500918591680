import React, { useContext } from 'react';
import { PricesPageContext } from 'providers/PricesPageProvider';
import { Button } from 'components/atoms/Button/Button';

import styles from 'components/organisms/PricesPageKcalWrapper/PricesPageKcalWrapper.module.scss';
export const PricesPageKcalWrapper = () => {
  const context = useContext(PricesPageContext);

  return (
    <div className={styles.buttonsWrapper}>
      {context.currentDiet.kcalPriceInfo.map((diet, index) => (
        <Button
          key={diet.kcal}
          text={`${diet.kcal} kcal`}
          backgroundColor={context.index === index ? '#ffb526' : 'white'}
          border="2px solid #ffb526"
          onClick={() => context.handleShowDietPrice(diet.price, index)}
        />
      ))}
    </div>
  );
};
