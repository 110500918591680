import React, { useContext } from 'react';
import { FollowUsItem } from 'components/atoms/FollowUsItem/FollowUsItem';
import { PricesPageModal } from 'components/organisms/PricesPageModal/PricesPageModal';
import { PricesPageContext } from 'providers/PricesPageProvider';
import { PricesPageKcalWrapper } from 'components/organisms/PricesPageKcalWrapper/PricesPageKcalWrapper';
import { PricesPagePhotoItem } from 'components/molecules/PricesPagePhotoItem/PricesPagePhotoItem';
import { PricesPageSliderWrapper } from 'components/organisms/PricesPageSliderWrapper/PricesPageSliderWrapper';
import { PricesPageDailyCostWrapper } from 'components/organisms/PricesPageDailyCostWrapper/PricesPageDailyCostWrapper';
import { PricesPageTotalPriceWrapper } from 'components/organisms/PricesPageTotalPriceWrapper/PricesPageTotalPriceWrapper';
import { PricesPageChooseDietButton } from 'components/molecules/PricesPageChooseDietButton/PricesPageChooseDietButton';
import { Helmet } from 'react-helmet-async';

import styles from 'components/templates/PricesPage/PricesPage.module.scss';
export const PricesPage = () => {
  const context = useContext(PricesPageContext);

  return (
    <>
      <section className={styles.container}>
        <Helmet>
          <title>Dailycious | Cennik</title>
          <meta name="description" content="" />
        </Helmet>
        <div className={styles.wrapper}>
          <div className={styles.priceWrapper}>
            <div className={styles.whiteWrapper}>
              <h1>CENNIK</h1>
              <h6 className={styles.whiteWrapper__h6}>dieta</h6>
              <PricesPageChooseDietButton />
              {context.isOpen ? <PricesPageModal /> : null}
              <PricesPageKcalWrapper />
              <PricesPageSliderWrapper />
              <PricesPageDailyCostWrapper />
            </div>
            <PricesPageTotalPriceWrapper />
          </div>
        </div>
        <PricesPagePhotoItem />
      </section>
      <FollowUsItem />
    </>
  );
};
