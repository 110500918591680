import React from 'react';
import PropTypes from 'prop-types';

import prevarrow from 'assets/icons/prevarrow.svg';

import styles from 'components/atoms/PrevArrowButton/PrevArrowButton.module.scss';
export const PrevArrowButton = ({ prevSlide }) => {
  return (
    <button className={styles.button} onClick={prevSlide}>
      <img className={styles.img} src={prevarrow} alt="previcon"></img>
    </button>
  );
};

PrevArrowButton.propTypes = {
  prevSlide: PropTypes.func,
};
