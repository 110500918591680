import React from 'react';
import PropTypes from 'prop-types';
import donevector from 'assets/icons/donevector.png';

import styles from 'components/molecules/DeliveryCitiesPageModal/DeliveryCitiesPageModal.module.scss';
export const DeliveryCitiesPageModal = ({ city }) => {
  return (
    <div className={styles.container}>
      <p className={styles.container__title}>{city}</p>
      <div className={styles.container__wrapper}>
        <img src={donevector} alt="vector"></img>
        <p>dowozimy</p>
      </div>
    </div>
  );
};

DeliveryCitiesPageModal.propTypes = {
  city: PropTypes.string,
};
