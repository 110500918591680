import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from 'components/atoms/FooterNavigationDietItem/FooterNavigationDietItem.module.scss';
export const FooterNavigationDietItem = ({ title, onClick, to }) => {
  return (
    <Link to={to}>
      <p onClick={onClick} className={styles.textList}>
        {title.toLowerCase()}
      </p>
    </Link>
  );
};

FooterNavigationDietItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
};
