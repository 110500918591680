import React, { useContext } from 'react';
import { PricesPageContext } from 'providers/PricesPageProvider';

import styles from 'components/molecules/PricesPagePhotoItem/PricesPagePhotoItem.module.scss';
export const PricesPagePhotoItem = () => {
  const context = useContext(PricesPageContext);

  return (
    <div className={styles.imgWrapper}>
      <img
        className={styles.img}
        src={context.currentDiet.image}
        alt="diet"
      ></img>
    </div>
  );
};
